"use strict";

import { ApiPromise, HttpProvider, WsProvider } from "@polkadot/api";

import { Connection, PublicKey } from "@solana/web3.js";

import { getAccount, TOKEN_PROGRAM_ID } from "@solana/spl-token";

import jsonrpc from "@polkadot/types/interfaces/jsonrpc.js";
import { ethers } from "ethers";
import axios from "axios";
import { v1 } from "uuid";
import querystring from "querystring";
import BN from "bn.js";
import crypto from "crypto";
import {
  render,
  renderDig,
  renderDigSpe,
  showBalance,
  showProcess,
} from "./views.js";
import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
import { TonClient4, WalletContractV4, Address } from "@ton/ton";
import { parse } from "path";
// import fetch from "node-fetch";

const loadPolkadot = async function (api, dotPrice) {
  const treasury = "13sD6psqwqT9FbETDAtpuMttGmk24ta3kiCZ7iB5c9XuvQXV";

  let accoutInfo = await api.query.system.account(treasury);
  let dotBalance =
    Number(accoutInfo.toHuman().data.free.replaceAll(",", "")) / 1e10;

  let token = {};
  token.balance = dotBalance;
  token.price = dotPrice;
  token.balanceUsd = token.balance * token.price;

  token.get1D = 0;
  token.get7D = 0;

  return [token];
};

const loadParallel = async function (api, dotPrice) {
  const ADDR3 = "5Gpyvjgmqmn5Ci45B4beAkRErRpe3HUi1Ce7DRZKwDWxGrfh"; // staking
  const ADDR5 = "5EnFhCyzy3Fee6DZeRGPpY8dfxqQAKp8VHoyvP8cCHjVcW9h"; // usdt

  let stakingDOT = await getBalancePara(api, 101, ADDR3, 0);
  let stakingsDOT = await getBalancePara(api, 1001, ADDR3, 0);

  let add5Dot = await getBalancePara(api, 101, ADDR5, 0);
  let add5Usdt = await getBalancePara(api, 102, ADDR5, 0, 6);

  let stakingDOTD1 = await getBalancePara(api, 101, ADDR3, 1);
  let stakingsDOTD1 = await getBalancePara(api, 1001, ADDR3, 1);

  let add5DotD1 = await getBalancePara(api, 101, ADDR5, 1);
  let add5UsdtD1 = await getBalancePara(api, 102, ADDR5, 1, 6);

  let stakingDOTD7 = await getBalancePara(api, 101, ADDR3, 7);
  let stakingsDOTD7 = await getBalancePara(api, 1001, ADDR3, 7);

  let add5DotD7 = await getBalancePara(api, 101, ADDR5, 7);
  let add5UsdtD7 = await getBalancePara(api, 102, ADDR5, 7, 6);

  // ------------------
  let baseRate = Number(await api.query.liquidStaking.exchangeRate()) / 1e18;

  let sdottoDot =
    (1 - getSOC(stakingDOT, stakingsDOT, baseRate * (1 - 0.021)) * 0.021) *
    baseRate;

  let sdottoDotD1 =
    (1 - getSOC(stakingDOTD1, stakingsDOTD1, baseRate * (1 - 0.021)) * 0.021) *
    baseRate;

  let sdottoDotD7 =
    (1 - getSOC(stakingDOTD7, stakingsDOTD7, baseRate * (1 - 0.021)) * 0.021) *
    baseRate;

  const sdotBalance = stakingDOT + stakingsDOT * sdottoDot;
  const sdotBalanceD1 = stakingDOTD1 + stakingsDOTD1 * sdottoDotD1;
  const sdotBalanceD7 = stakingDOTD7 + stakingsDOTD7 * sdottoDotD7;

  const add5Balance = add5Dot + add5Usdt / dotPrice;
  const add5BalanceD1 = add5DotD1 + add5UsdtD1 / dotPrice;
  const add5BalanceD7 = add5DotD7 + add5UsdtD7 / dotPrice;

  let token3 = {};
  let token5 = {};

  // sdot
  token3.balance = sdotBalance;
  token3.price = dotPrice;
  token3.balanceUsd = token3.balance * token3.price;

  // usdt
  token5.balance = add5Balance;
  token5.price = dotPrice;
  token5.balanceUsd = add5Balance * token5.price;

  token3.onlinedate = "4/26/2022";
  token5.onlinedate = "11/22/2022";

  token3.get1D =
    sdotBalance -
    (sdotBalanceD1 == 0 || isNaN(sdotBalanceD1) ? sdotBalance : sdotBalanceD1);
  token3.get7D =
    sdotBalance -
    (sdotBalanceD7 == 0 || isNaN(sdotBalanceD7) ? sdotBalance : sdotBalanceD7);

  token5.get1D =
    add5Balance -
    (add5BalanceD1 == 0 || isNaN(add5BalanceD1) ? add5Balance : add5BalanceD1);
  token5.get7D =
    add5Balance -
    (add5BalanceD7 == 0 || isNaN(add5BalanceD7) ? add5Balance : add5BalanceD7);

  let tokens = [token3, token5];
  return tokens;
};

const loadParallelLcdot = async function () {
  // Construct
  const wsProvider = new WsProvider("wss://rpc.parallel.fi");
  const api = await ApiPromise.create({ provider: wsProvider, rpc: jsonrpc });

  const address = "p8GNVpNYFLR4M9Eu7jMzYMTqvvM1JgxzMB1zYG9NhDYFEjVjK"; // cdot 7

  let balanceCdot = await getBalancePara(api, 200060013, address, 0);
  let balanceLcdot = await getBalancePara(api, 106, address, 0);

  let balanceCdotD1 = await getBalancePara(api, 200060013, address, 1);
  let balanceLcdotD1 = await getBalancePara(api, 106, address, 1);

  let balanceCdotD7 = await getBalancePara(api, 200060013, address, 7);
  let balanceLcdotD7 = await getBalancePara(api, 106, address, 7);

  let balance = balanceCdot + balanceLcdot;
  let balanceD1 = balanceCdotD1 + balanceLcdotD1;
  let balanceD7 = balanceCdotD7 + balanceLcdotD7;

  let pool613 = await api.query.amm.pools(200060013, 101);
  let acDOT6 = pool613.toHuman().baseAmount.replace(/,/g, "");
  let aDOT6 = pool613.toHuman().quoteAmount.replace(/,/g, "");
  let cDOT6Price = aDOT6 / acDOT6;

  const dotPrice = await getPrice("polkadot");

  let token = {};

  token.balance = balanceCdot + balanceLcdot;
  token.price = cDOT6Price * dotPrice;
  token.balanceUsd = token.balance * token.price;

  token.onlinedate = "2/23/2023";

  token.get1D =
    balance - (balanceD1 == 0 || isNaN(balanceD1) ? balance : balanceD1);

  token.get7D =
    balance - (balanceD7 == 0 || isNaN(balanceD7) ? balance : balanceD7);

  return token;
};

const loadEachIDEX = async function (public_key, API_key, API_secret) {
  const balance_url = "https://api-matic.idex.io/v1/balances";

  let tnonce = v1();
  let parametersSim = {
    nonce: tnonce,
    wallet: public_key,
    asset: "USDC",
  };
  let stringifiedparameters = querystring.stringify(parametersSim);

  let signatureparameters = crypto
    .createHmac("sha256", API_secret)
    .update(stringifiedparameters)
    .digest("hex");

  let parameters = {
    params: parametersSim,
    headers: {
      "IDEX-API-KEY": API_key,
      "IDEX-HMAC-SIGNATURE": signatureparameters,
    },
  };
  let amount = await axios.get(balance_url, parameters);

  let token = {};

  token.balance = Number(amount.data[0].quantity);
  token.price = await getPrice("usd-coin");
  token.balanceUsd = token.balance * token.price;
  return token;
};

const loadIDEX = async function () {
  const API_secret1 = "B+sxSI9131Xiu7H+eD7ROEMrVnkpaT+Z";
  const API_key1 = "a69bd7e0-022d-46a9-872b-bf8d9269fcd8";
  const public_key1 = "0xFcAAa6f52bEf837bdEF69C5ff116896E06FBC212";

  let idex1 = await loadEachIDEX(public_key1, API_key1, API_secret1);

  // const API_secret2 = "9v1pSjo6b6+nepLfSaHEe0z72jvi+jgu";
  // const API_key2 = "ca3ee9e1-d33b-49ee-b515-0df219e4ca22";
  // const public_key2 = "0x1440cBF547Ffa71fd61B07f7E731C2C8fEF12431";

  // let idex2 = await loadEachIDEX(public_key2, API_key2, API_secret2);

  idex1.onlinedate = "9/3/2022";
  // idex2.onlinedate = "5/20/2022";
  idex1.get1D = -999;
  // idex2.get1D = -1;
  idex1.get7D = -999;
  // idex2.get7D = -1;

  return idex1;
};

const loadBifrost = async function (apiBiforst, bncPrice) {
  // Construct
  // const wsProvider = new WsProvider("wss://hk.p.bifrost-rpc.liebi.com/ws");
  // const api = await ApiPromise.create({ provider: wsProvider, rpc: jsonrpc });

  // const wsProviderKusama = new WsProvider("wss://bifrost-rpc.liebi.com/ws");

  // const apiBiforst = await ApiPromise.create({
  //   provider: wsProviderKusama,
  //   rpc: jsonrpc,
  // });

  // const addressDot = "eSMMUKJkGSXKpBJ5K53XZgMUhoqVhCobQMkYPR7ec7J325c";
  const addressBnc = "dx8hxgcX8U9rQjfeFc7TQkg1dwaoXNSa69DuMArMWpawKRZ";
  const addressFil = "dx8hxgcX8U9rQjfeFc7TQkg1dwaoXNSa69DuMArMWpawKRZ";

  // let totalIssuranceDot = await api.query.tokens.totalIssuance({ vToken2: 0 });
  // let totalStakedDot = await api.query.vtokenMinting.tokenPool({ Token2: 0 });
  // let baseRateDot = totalStakedDot / totalIssuranceDot;

  // const balanceDot = await getBalanceBifrost(api, "Token2", 0, addressDot, 0);
  // const balanceDotD1 = await getBalanceBifrost(api, "Token2", 0, addressDot, 1);
  // const balanceDtoD7 = await getBalanceBifrost(api, "Token2", 0, addressDot, 7);

  // const balanceVdot = await getBalanceBifrost(api, "vToken2", 0, addressDot, 0);
  // const balanceVdotD1 = await getBalanceBifrost(
  //   api,
  //   "vToken2",
  //   0,
  //   addressDot,
  //   1
  // );
  // const balanceVdotD7 = await getBalanceBifrost(
  //   api,
  //   "vToken2",
  //   0,
  //   addressDot,
  //   7
  // );

  // let tokenDot = {};

  // let vdot2Dot =
  //   1 -
  //   ((balanceVdot * (1 - 0.05)) / (balanceDot + balanceVdot * (1 - 0.05))) *
  //     0.08;
  // let vdot2DotD1 =
  //   1 -
  //   ((balanceVdotD1 * (1 - 0.05)) /
  //     (balanceDotD1 + balanceVdotD1 * (1 - 0.05))) *
  //     0.08;
  // let vdot2DotD7 =
  //   1 -
  //   ((balanceVdotD7 * (1 - 0.05)) /
  //     (balanceDtoD7 + balanceVdotD7 * (1 - 0.05))) *
  //     0.08;

  // const dotPrice = await getPrice("polkadot");

  // const dotBalance = balanceDot + balanceVdot * vdot2Dot * baseRateDot;
  // const dotBalanceD1 = balanceDotD1 + balanceVdotD1 * vdot2DotD1 * baseRateDot;
  // const dotBalanceD7 = balanceDtoD7 + balanceVdotD7 * vdot2DotD7 * baseRateDot;

  // tokenDot.balance = dotBalance;
  // tokenDot.price = dotPrice;
  // tokenDot.balanceUsd = tokenDot.balance * tokenDot.price;

  // tokenDot.onlinedate = "9/24/2022";

  // tokenDot.get1D =
  //   dotBalance -
  //   (dotBalanceD1 == 0 || isNaN(dotBalanceD1) ? dotBalance : dotBalanceD1);
  // tokenDot.get7D =
  //   dotBalance -
  //   (dotBalanceD7 == 0 || isNaN(dotBalanceD7) ? dotBalance : dotBalanceD7);

  // --------------------------------------------------

  let totalIssuranceBnc = await apiBiforst.query.tokens.totalIssuance({
    VToken: "BNC",
  });
  let totalStakedBnc = await apiBiforst.query.vtokenMinting.tokenPool({
    Native: "BNC",
  });
  let baseRateBnc = totalStakedBnc / totalIssuranceBnc;

  const balanceBnc = await getBalanceBifrostBnc(apiBiforst, addressBnc, 0);
  const balanceBncD1 = await getBalanceBifrostBnc(apiBiforst, addressBnc, 1);
  const balanceBncD7 = await getBalanceBifrostBnc(apiBiforst, addressBnc, 7);

  const balanceVbnc = await getBalanceBifrost(
    apiBiforst,
    "vToken",
    "BNC",
    addressBnc,
    0,
    12
  );
  const balanceVbncD1 = await getBalanceBifrost(
    apiBiforst,
    "vToken",
    "BNC",
    addressBnc,
    1,
    12
  );
  const balanceVbncD7 = await getBalanceBifrost(
    apiBiforst,
    "vToken",
    "BNC",
    addressBnc,
    7,
    12
  );
  let tokenBnc = {};

  let vbnc2Bnc =
    1 -
    ((balanceVbnc * (1 - 0.05)) / (balanceBnc + balanceVbnc * (1 - 0.05))) *
      0.08;
  let vbnc2BncD1 =
    1 -
    ((balanceVbncD1 * (1 - 0.05)) /
      (balanceBncD1 + balanceVbncD1 * (1 - 0.05))) *
      0.08;
  let vbnc2BncD7 =
    1 -
    ((balanceVbncD7 * (1 - 0.05)) /
      (balanceBncD7 + balanceVbncD7 * (1 - 0.05))) *
      0.08;

  // const bncPrice = await getPrice("bifrost-native-coin");

  const bncBalance = balanceBnc + balanceVbnc * vbnc2Bnc * baseRateBnc;
  const bncBalanceD1 = balanceBncD1 + balanceVbncD1 * vbnc2BncD1 * baseRateBnc;
  const bncBalanceD7 = balanceBncD7 + balanceVbncD7 * vbnc2BncD7 * baseRateBnc;

  tokenBnc.balance = bncBalance;
  tokenBnc.price = bncPrice;
  tokenBnc.balanceUsd = tokenBnc.balance * tokenBnc.price;

  tokenBnc.onlinedate = "3/2/2022";

  tokenBnc.get1D =
    bncBalance -
    (bncBalanceD1 == 0 || isNaN(bncBalanceD1) ? bncBalance : bncBalanceD1);
  tokenBnc.get7D =
    bncBalance -
    (bncBalanceD7 == 0 || isNaN(bncBalanceD7) ? bncBalance : bncBalanceD7);

  // return [tokenDot, tokenBnc];
  return [tokenBnc];
};

const loadBifrost2 = async function (api, dotPrice) {
  // Construct
  // const wsProvider = new WsProvider("wss://hk.p.bifrost-rpc.liebi.com/ws");
  // const api = await ApiPromise.create({ provider: wsProvider, rpc: jsonrpc });

  const addressDot = "dx8hxgcX8U9rQjfeFc7TQkg1dwaoXNSa69DuMArMWpawKRZ";

  let totalIssuranceDot = await api.query.tokens.totalIssuance({ vToken2: 0 });
  let totalStakedDot = await api.query.vtokenMinting.tokenPool({ Token2: 0 });
  let baseRateDot = totalStakedDot / totalIssuranceDot;

  const balanceDot = await getBalanceBifrost(api, "Token2", 0, addressDot, 0);
  const balanceDotD1 = await getBalanceBifrost(api, "Token2", 0, addressDot, 1);
  const balanceDotD7 = await getBalanceBifrost(api, "Token2", 0, addressDot, 7);

  const balanceVdot = await getBalanceBifrost(api, "vToken2", 0, addressDot, 0);
  const balanceVdotD1 = await getBalanceBifrost(
    api,
    "vToken2",
    0,
    addressDot,
    1
  );
  const balanceVdotD7 = await getBalanceBifrost(
    api,
    "vToken2",
    0,
    addressDot,
    7
  );

  let tokenDot = {};

  let vdot2Dot =
    1 -
    ((balanceVdot * (1 - 0.05)) / (balanceDot + balanceVdot * (1 - 0.05))) *
      0.08;
  let vdot2DotD1 =
    1 -
    ((balanceVdotD1 * (1 - 0.05)) /
      (balanceDotD1 + balanceVdotD1 * (1 - 0.05))) *
      0.08;
  let vdot2DotD7 =
    1 -
    ((balanceVdotD7 * (1 - 0.05)) /
      (balanceDotD7 + balanceVdotD7 * (1 - 0.05))) *
      0.08;

  // const dotPrice = await getPrice("polkadot");

  const dotBalance = balanceDot + balanceVdot * vdot2Dot * baseRateDot;
  const dotBalanceD1 = balanceDotD1 + balanceVdotD1 * vdot2DotD1 * baseRateDot;
  const dotBalanceD7 = balanceDotD7 + balanceVdotD7 * vdot2DotD7 * baseRateDot;

  tokenDot.balance = dotBalance;
  tokenDot.price = dotPrice;
  tokenDot.balanceUsd = tokenDot.balance * tokenDot.price;

  tokenDot.onlinedate = "8/31/2023";

  tokenDot.get1D =
    dotBalance -
    (dotBalanceD1 == 0 || isNaN(dotBalanceD1) ? dotBalance : dotBalanceD1);
  tokenDot.get7D =
    dotBalance -
    (dotBalanceD7 == 0 || isNaN(dotBalanceD7) ? dotBalance : dotBalanceD7);

  return [tokenDot];
};

const loadAcala = async function (api, dotPrice) {
  const address1 = "5HjffkdzwnnCmvoaV2Mz2Lu3uDfRbQM77YERUMhdWruuCwhu";
  const address2 = "23nHZzS2i6AjiKhWPgNjhaBZ86PLv4dtZUHsCTyS3XHSQHeK";
  const address3 = "26EE6hkKLQr4RSTJix4qMFRDJ9qVeH2wPUPQxE1zJzqmwruC";
  const address4 = "5HYYCb6tbMnW9W4BpS23qsn8oXuDqoosxUGD8MCKzuhUnfyF";

  let token1 = {};
  let token2 = {};
  let token3 = {};
  let token4 = {};

  await acalaLoadAddress(api, address1, "9/25/2022", token1, dotPrice);
  await acalaLoadAddress(api, address2, "6/18/2024", token2, dotPrice);
  await acalaLoadAddress(api, address3, "11/10/2022", token3, dotPrice);
  await acalaLoadAddress(api, address4, "11/10/2022", token4, dotPrice);

  return [token1, token2, token3, token4];
};

const loadBifrost3 = async function (api, usdtPrice, ksmPrice) {
  const addressUsdt = "hPvN39V7KJ39pEssrh8RuKkdkWqcogNKgHhKkYnr5WM5SeE";
  const addressKsm = "dzo879LrmM7dfYw5iYJ9bzW5xt7zvSr8GjAScpL3EzojJ4H";

  const balanceUsdt = await getBalanceBifrost(
    api,
    "Token2",
    0,
    addressUsdt,
    0,
    6
  );
  const balanceUsdtD1 = await getBalanceBifrost(
    api,
    "Token2",
    0,
    addressUsdt,
    1,
    6
  );
  const balanceUsdtD7 = await getBalanceBifrost(
    api,
    "Token2",
    0,
    addressUsdt,
    7,
    6
  );
  // ------------------------------
  const balanceKsm = await getBalanceBifrost(
    api,
    "Token",
    "KSM",
    addressKsm,
    0,
    12
  );
  const balanceKsmD1 = await getBalanceBifrost(
    api,
    "Token",
    "KSM",
    addressKsm,
    1,
    12
  );
  const balanceKsmD7 = await getBalanceBifrost(
    api,
    "Token",
    "KSM",
    addressKsm,
    7,
    12
  );

  // ------------------------------
  let tokenUsdt = {};

  const usdtBalance = balanceUsdt;
  const usdtBalanceD1 = balanceUsdtD1;
  const usdtBalanceD7 = balanceUsdtD7;

  tokenUsdt.balance = usdtBalance;
  tokenUsdt.price = usdtPrice;
  tokenUsdt.balanceUsd = tokenUsdt.balance * tokenUsdt.price;

  tokenUsdt.onlinedate = "10/5/2023";

  tokenUsdt.get1D =
    usdtBalance -
    (usdtBalanceD1 == 0 || isNaN(usdtBalanceD1) ? usdtBalance : usdtBalanceD1);
  tokenUsdt.get7D =
    usdtBalance -
    (usdtBalanceD7 == 0 || isNaN(usdtBalanceD7) ? usdtBalance : usdtBalanceD7);

  // ------------------------------
  let tokenKsm = {};

  const ksmBalance = balanceKsm;
  const ksmBalanceD1 = balanceKsmD1;
  const ksmBalanceD7 = balanceKsmD7;

  tokenKsm.balance = ksmBalance;
  tokenKsm.price = ksmPrice;
  tokenKsm.balanceUsd = tokenKsm.balance * tokenKsm.price;

  tokenKsm.onlinedate = "6/21/2024";

  tokenKsm.get1D =
    ksmBalance -
    (ksmBalanceD1 == 0 || isNaN(ksmBalanceD1) ? ksmBalance : ksmBalanceD1);
  tokenKsm.get7D =
    ksmBalance -
    (ksmBalanceD7 == 0 || isNaN(ksmBalanceD7) ? ksmBalance : ksmBalanceD7);

  return [tokenUsdt, tokenKsm];
};

const loadBifrost4 = async function (api, dotPrice, filPrice, glmrPrice) {
  // Construct
  // const wsProvider = new WsProvider("wss://hk.p.bifrost-rpc.liebi.com/ws");
  // const api = await ApiPromise.create({ provider: wsProvider, rpc: jsonrpc });

  const address = "5CZxSJiyuLuTmc9MQrnJaKBF2egJAChVnZEZhcpaK9TGnHBz";
  const addressT = "ebAen8GFahWBwfEeNAyAmVS9gbnPzMAtMXLop2tPH6qcU1t";

  const balanceDot = await getBalanceBifrost(api, "Token2", 0, address, 0);
  const balanceDotD1 = await getBalanceBifrost(api, "Token2", 0, address, 1);
  const balanceDotD7 = await getBalanceBifrost(api, "Token2", 0, address, 7);

  const balanceFil = await getBalanceBifrost(api, "Token2", 4, address, 0, 18);
  const balanceFilD1 = await getBalanceBifrost(
    api,
    "Token2",
    4,
    address,
    1,
    18
  );
  const balanceFilD7 = await getBalanceBifrost(
    api,
    "Token2",
    4,
    address,
    7,
    18
  );

  const balanceGlmr = await getBalanceBifrost(api, "Token2", 1, address, 0, 18);
  const balanceGlmrD1 = await getBalanceBifrost(
    api,
    "Token2",
    1,
    address,
    1,
    18
  );
  const balanceGlmrD7 = await getBalanceBifrost(
    api,
    "Token2",
    1,
    address,
    7,
    18
  );
  ////////////////////////////////////////////////////////////////
  const balanceDotT = await getBalanceBifrost(api, "Token2", 0, addressT, 0);
  const balanceDotD1T = await getBalanceBifrost(api, "Token2", 0, addressT, 1);
  const balanceDotD7T = await getBalanceBifrost(api, "Token2", 0, addressT, 7);

  const balanceFilT = await getBalanceBifrost(
    api,
    "Token2",
    4,
    addressT,
    0,
    18
  );
  const balanceFilD1T = await getBalanceBifrost(
    api,
    "Token2",
    4,
    addressT,
    1,
    18
  );
  const balanceFilD7T = await getBalanceBifrost(
    api,
    "Token2",
    4,
    addressT,
    7,
    18
  );

  const balanceGlmrT = await getBalanceBifrost(
    api,
    "Token2",
    1,
    addressT,
    0,
    18
  );
  const balanceGlmrD1T = await getBalanceBifrost(
    api,
    "Token2",
    1,
    addressT,
    1,
    18
  );
  const balanceGlmrD7T = await getBalanceBifrost(
    api,
    "Token2",
    1,
    addressT,
    7,
    18
  );

  let token = {};

  // const dotPrice = await getPrice("polkadot");
  // const filPrice = await getPrice("filecoin");
  // const glmrPrice = await getPrice("moonbeam");

  const fil2dot = filPrice / dotPrice;
  const glmr2dot = glmrPrice / dotPrice;

  const balance = balanceDot + balanceFil * fil2dot + balanceGlmr * glmr2dot;
  const balanceD1 =
    balanceDotD1 + balanceFilD1 * fil2dot + balanceGlmrD1 * glmr2dot;
  const balanceD7 =
    balanceDotD7 + balanceFilD7 * fil2dot + balanceGlmrD7 * glmr2dot;

  const balanceT =
    balanceDotT + balanceFilT * fil2dot + balanceGlmrT * glmr2dot;
  const balanceD1T =
    balanceDotD1T + balanceFilD1T * fil2dot + balanceGlmrD1T * glmr2dot;
  const balanceD7T =
    balanceDotD7T + balanceFilD7T * fil2dot + balanceGlmrD7T * glmr2dot;

  let get1DT =
    balanceT - (balanceD1T == 0 || isNaN(balanceD1T) ? balanceT : balanceD1T);
  let get7DT =
    balanceT - (balanceD7T == 0 || isNaN(balanceD7T) ? balanceT : balanceD7T);

  token.balance = balance + balanceT;
  token.price = dotPrice;
  token.balanceUsd = token.balance * token.price;

  token.onlinedate = "11/12/2023";

  token.get1D =
    balance -
    (balanceD1 == 0 || isNaN(balanceD1) ? balance : balanceD1) +
    get1DT;
  token.get7D =
    balance -
    (balanceD7 == 0 || isNaN(balanceD7) ? balance : balanceD7) +
    get7DT;

  return [token];
};

const loadBifrost5 = async function (api, dotPrice) {
  // // Construct
  // const wsProvider = new WsProvider("wss://hk.p.bifrost-rpc.liebi.com/ws");
  // const api = await ApiPromise.create({ provider: wsProvider, rpc: jsonrpc });

  const address = "hCntscNktJLXPVVDGMCFSCqY4kdsD99AcKUyk3VL8Hvf1mo";

  let [poolStaking0, tokenRateCaches0] = await Promise.all([
    api.query.stableAsset.pools(0), // dot -vdot

    api.query.stableAsset.tokenRateCaches(0, {
      VToken2: 0,
    }),
  ]);

  let baseRate0 =
    Number(tokenRateCaches0.value[0]) / Number(tokenRateCaches0.value[1]);
  let stakeRate0 = getStakingRateBN(poolStaking0);
  let dotToVdot = stakeRate0 * baseRate0;

  const balanceVdot = await getBalanceBifrost(api, "VToken2", 0, address, 0);
  const balanceVdotD1 = await getBalanceBifrost(api, "VToken2", 0, address, 1);
  const balanceVdotD7 = await getBalanceBifrost(api, "VToken2", 0, address, 7);

  const balanceUsdt = await getBalanceBifrost(api, "Token2", 2, address, 0, 6);
  const balanceUsdtD1 = await getBalanceBifrost(
    api,
    "Token2",
    2,
    address,
    1,
    6
  );
  const balanceUsdtD7 = await getBalanceBifrost(
    api,
    "Token2",
    2,
    address,
    7,
    6
  );

  let token = {};

  // const dotPrice = await getPrice("polkadot");

  const usdt2dot = 1 / dotPrice;

  const balance = (balanceVdot + balanceUsdt * usdt2dot) / dotToVdot;
  const balanceD1 = (balanceVdotD1 + balanceUsdtD1 * usdt2dot) / dotToVdot;
  const balanceD7 = (balanceVdotD7 + balanceUsdtD7 * usdt2dot) / dotToVdot;

  token.balance = balance;
  token.price = dotPrice;
  token.balanceUsd = token.balance * token.price;

  token.onlinedate = "11/14/2023";

  token.get1D =
    balance - (balanceD1 == 0 || isNaN(balanceD1) ? balance : balanceD1);
  token.get7D =
    balance - (balanceD7 == 0 || isNaN(balanceD7) ? balance : balanceD7);

  return [token];
};

const loadBifrost6 = async function (api, dotPrice) {
  const address = "hPvN39V7KJ39pEssrh8RuKkdkWqcogNKgHhKkYnr5WM5SeE";

  const balanceDot = await getBalanceBifrost(api, "Token2", 0, address, 0);
  const balanceDotD1 = await getBalanceBifrost(api, "Token2", 0, address, 1);
  const balanceDotD7 = await getBalanceBifrost(api, "Token2", 0, address, 7);

  let token = {};

  token.balance = balanceDot;
  token.price = dotPrice;
  token.balanceUsd = token.balance * token.price;

  token.onlinedate = "2/5/2023";

  token.get1D =
    balanceDot -
    (balanceDotD1 == 0 || isNaN(balanceDotD1) ? balanceDot : balanceDotD1);
  token.get7D =
    balanceDot -
    (balanceDotD7 == 0 || isNaN(balanceDotD7) ? balanceDot : balanceDotD7);

  return [token];
};

const loadParallelLdot = async function (api, dotPrice) {
  // // Construct
  // const wsProvider = new WsProvider("wss://rpc.parallel.fi");
  // const api = await ApiPromise.create({ provider: wsProvider, rpc: jsonrpc });

  // const addressLondon = "5HYYCb6tbMnW9W4BpS23qsn8oXuDqoosxUGD8MCKzuhUnfyF";
  const address2 = "p8BuPZwB6AP5TKuJbC9cbjvwHvjCdjH5tt8b4eB4pfzcKC8Kv";

  // let dotParallel = await getBalancePara(api, 101, addressLondon, 0);
  // let ldotParallel = await getBalancePara(api, 110, addressLondon, 0);

  // let dotParallelD1 = await getBalancePara(api, 101, addressLondon, 1);
  // let ldotParallelD1 = await getBalancePara(api, 110, addressLondon, 1);

  // let dotParallelD7 = await getBalancePara(api, 101, addressLondon, 7);
  // let ldotParallelD7 = await getBalancePara(api, 110, addressLondon, 7);

  let balanceDot2 = await getBalancePara(api, 101, address2, 0);
  let balanceUsdt2 = await getBalancePara(api, 102, address2, 0, 6);

  let balanceDot2D1 = await getBalancePara(api, 101, address2, 1);
  let balanceUsdt2D1 = await getBalancePara(api, 102, address2, 1, 6);

  let balanceDot2D7 = await getBalancePara(api, 101, address2, 7);
  let balanceUsdt2D7 = await getBalancePara(api, 102, address2, 7, 6);

  // const dotPrice = await getPrice("polkadot");

  // const parallelBalance = dotParallel + 0.1298 * ldotParallel;
  // const parallelBalanceD1 = dotParallelD1 + 0.1298 * ldotParallelD1;
  // const parallelBalanceD7 = dotParallelD7 + 0.1298 * ldotParallelD7;

  // let token = {};
  // token.balance = parallelBalance;
  // token.price = dotPrice;
  // token.balanceUsd = token.balance * token.price;

  // token.onlinedate = "9/23/2023";

  // token.get1D =
  //   parallelBalance -
  //   (parallelBalanceD1 == 0 || isNaN(parallelBalanceD1)
  //     ? parallelBalance
  //     : parallelBalanceD1);

  // token.get7D =
  //   parallelBalance -
  //   (parallelBalanceD7 == 0 || isNaN(parallelBalanceD7)
  //     ? parallelBalance
  //     : parallelBalanceD7);

  //
  let token2 = {};
  let balance2 = balanceDot2 + balanceUsdt2 / dotPrice;
  let balance2D1 = balanceDot2D1 + balanceUsdt2D1 / dotPrice;
  let balance2D7 = balanceDot2D7 + balanceUsdt2D7 / dotPrice;

  token2.balance = balance2;
  token2.price = dotPrice;
  token2.balanceUsd = token2.balance * token2.price;

  token2.onlinedate = "10/15/2023";

  token2.get1D =
    balance2 - (balance2D1 == 0 || isNaN(balance2D1) ? balance2 : balance2D1);

  token2.get7D =
    balance2 - (balance2D7 == 0 || isNaN(balance2D7) ? balance2 : balance2D7);

  return [token2];
};

const loadCross = async function (api, dotPrice) {
  // ------------------------------------------------------------------------
  const addressAcala = "5D89xoKcRRg2iwwEMriNsGbWaa8SaAR2AbEM2a7SvdRfGsaQ";

  //-------------------------------------------

  let tokenAcala = {};
  let tokenAcalaLdot = {};

  await acalaLoadAddressBalance(api, addressAcala, tokenAcala);
  await acalaLoadAddressLdotBalance(api, addressAcala, tokenAcalaLdot);

  let [
    poolStaking,
    totalIssuance,
    totalVoidLiquid,
    totalStakingBonded,
    boundPool,
  ] = await Promise.all([
    api.query.stableAsset.pools(0),
    api.query.tokens.totalIssuance({ TOKEN: "LDOT" }),
    api.query.homa.totalVoidLiquid(),
    api.query.homa.totalStakingBonded(),
    api.query.homa.toBondPool(),
  ]);

  const exchangeRate =
    (Number(totalStakingBonded) + Number(boundPool)) /
    (Number(totalIssuance) + Number(totalVoidLiquid));

  let stakeRate = getStakingRate(poolStaking);

  let ldotToDot = stakeRate * exchangeRate;

  let token1 = {};
  let balance1 = tokenAcala.balance + ldotToDot * tokenAcalaLdot.balance;

  let balance1D1 = tokenAcala.balanceD1 + ldotToDot * tokenAcalaLdot.balanceD1;

  let balance1D7 = tokenAcala.balanceD7 + ldotToDot * tokenAcalaLdot.balanceD7;

  token1.balance = balance1;
  token1.price = dotPrice;
  token1.balanceUsd = token1.balance * token1.price;
  token1.onlinedate = "5/31/2024";

  token1.get1D = balance1 - (balance1D1 == 0 ? balance1 : balance1D1);
  token1.get7D = balance1 - (balance1D7 == 0 ? balance1 : balance1D7);
  // -----------------------------------
  return [token1];
};

const loadCrossLdot = async function (apiAcala, apiParallel, dotPrice) {
  const addressAcala = "239SA5JxPvr2FuAnhr4PfD4CfuTJDexvt2wguMPXtfNkrGu6";
  const addressPara = "p8DDPyarn2VA6uCZ5SGYmG9DoaK6y3xR5yAEsD1MtGT6X3PFP";

  let tokenAcala = {};
  let tokenParallel = {};

  await acalaLoadAddress(apiAcala, addressAcala, "", tokenAcala, dotPrice);
  await parallelLoadAddress(
    apiParallel,
    addressPara,
    "",
    tokenParallel,
    dotPrice
  );

  let tokenAcalaLdot = {};
  let tokenParallelLdot = {};
  await acalaLoadAddressLdot(
    apiAcala,
    addressAcala,
    "",
    tokenAcalaLdot,
    dotPrice
  );
  await parallelLoadAddressLdot(
    apiParallel,
    addressPara,
    "",
    tokenParallelLdot,
    dotPrice
  );

  let token = {};

  token.balance =
    tokenAcala.balance +
    tokenParallel.balance +
    0.1298 * (tokenAcalaLdot.balance + tokenParallelLdot.balance);

  token.price = tokenAcala.price;
  token.balanceUsd = token.balance * token.price;

  token.onlinedate = "1/24/2023";

  token.get1D =
    tokenAcala.get1D +
    tokenParallel.get1D +
    0.1298 * (tokenAcalaLdot.get1D + tokenParallelLdot.get1D);

  token.get7D =
    tokenAcala.get7D +
    tokenParallel.get7D +
    0.1298 * (tokenAcalaLdot.get7D + tokenParallelLdot.get7D);

  return token;
};

const loadCrossAca = async function (
  apiAcala,
  apiParallel,
  dotPrice,
  acaPrice
) {
  // // Construct
  const addressAcala = "239SA5JxPvr2FuAnhr4PfD4CfuTJDexvt2wguMPXtfNkrGu6";
  const addressPara = "p8DDPyarn2VA6uCZ5SGYmG9DoaK6y3xR5yAEsD1MtGT6X3PFP";

  let tokenAcala = {};
  let tokenParallel = {};

  await acalaLoadAddress(apiAcala, addressAcala, "", tokenAcala, dotPrice);
  await parallelLoadAddress(
    apiParallel,
    addressPara,
    "",
    tokenParallel,
    dotPrice
  );

  let tokenAcalaAca = {};
  let tokenParallelAca = {};
  {
    const balance = await getBalanceAcala(apiAcala, "ACA", addressAcala, 0, 12);
    const balanceD1 = await getBalanceAcala(
      apiAcala,
      "ACA",
      addressAcala,
      1,
      12
    );
    const balanceD7 = await getBalanceAcala(
      apiAcala,
      "ACA",
      addressAcala,
      7,
      12
    );
    tokenAcalaAca.balance = balance;
    tokenAcalaAca.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
    tokenAcalaAca.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);
  }
  {
    const balance = await getBalancePara(apiParallel, 108, addressPara, 0, 12);
    const balanceD1 = await getBalancePara(
      apiParallel,
      108,
      addressPara,
      1,
      12
    );
    const balanceD7 = await getBalancePara(
      apiParallel,
      108,
      addressPara,
      7,
      12
    );
    tokenParallelAca.balance = balance;
    tokenParallelAca.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
    tokenParallelAca.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);
  }

  let token = {};

  let acaToDot = acaPrice / dotPrice;

  token.balance =
    tokenAcala.balance +
    tokenParallel.balance +
    acaToDot * (tokenAcalaAca.balance + tokenParallelAca.balance);

  token.price = tokenAcala.price;
  token.balanceUsd = token.balance * token.price;

  token.onlinedate = "1/24/2023";

  token.get1D =
    tokenAcala.get1D +
    tokenParallel.get1D +
    acaToDot * (tokenAcalaAca.get1D + tokenParallelAca.get1D);

  token.get7D =
    tokenAcala.get7D +
    tokenParallel.get7D +
    acaToDot * (tokenAcalaAca.get7D + tokenParallelAca.get7D);

  return token;
};

const loadAcaRef = async function (api, dotPrice, acaPrice) {
  // // Construct
  const addressAcala = "239SA5JxPvr2FuAnhr4PfD4CfuTJDexvt2wguMPXtfNkrGu6";

  let tokenAcala = {};

  // await acalaLoadAddress(api, addressAcala, "", tokenAcala, dotPrice);

  let tokenAcalaAca = {};

  // const balance = await getBalanceAcala(api, "ACA", addressAcala, 0, 12);
  // const balanceD1 = await getBalanceAcala(api, "ACA", addressAcala, 1, 12);

  // const balanceD7 = await getBalanceAcala(api, "ACA", addressAcala, 7, 12);

  const balanceLdot = await getBalanceAcala(api, "LDOT", addressAcala, 0);
  const balanceD1Ldot = await getBalanceAcala(api, "LDOT", addressAcala, 1);
  const balanceD7Ldot = await getBalanceAcala(api, "LDOT", addressAcala, 7);

  tokenAcala.balance = balanceLdot;
  tokenAcala.get1D =
    balanceLdot - (balanceD1Ldot == 0 ? balanceLdot : balanceD1Ldot);
  tokenAcala.get7D =
    balanceLdot - (balanceD7Ldot == 0 ? balanceLdot : balanceD7Ldot);

  const balance = await getBalanceAcalaAca(api, addressAcala, 0, 12);
  const balanceD1 = await getBalanceAcalaAca(api, addressAcala, 1, 12);
  const balanceD7 = await getBalanceAcalaAca(api, addressAcala, 7, 12);

  tokenAcalaAca.balance = balance;
  tokenAcalaAca.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
  tokenAcalaAca.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);

  let token = {};

  let [
    poolStaking,
    totalIssuance,
    totalVoidLiquid,
    totalStakingBonded,
    boundPool,
  ] = await Promise.all([
    api.query.stableAsset.pools(0),
    api.query.tokens.totalIssuance({ TOKEN: "LDOT" }),
    api.query.homa.totalVoidLiquid(),
    api.query.homa.totalStakingBonded(),
    api.query.homa.toBondPool(),
  ]);

  const exchangeRate =
    (Number(totalStakingBonded) + Number(boundPool)) /
    (Number(totalIssuance) + Number(totalVoidLiquid));

  let stakeRate = getStakingRate(poolStaking);

  let acaToDot = acaPrice / (dotPrice * stakeRate * exchangeRate);

  token.balance = tokenAcala.balance + acaToDot * tokenAcalaAca.balance;

  token.price = dotPrice * stakeRate * exchangeRate;
  token.balanceUsd = token.balance * token.price;

  token.onlinedate = "5/31/2023";

  token.get1D = tokenAcala.get1D + acaToDot * tokenAcalaAca.get1D;

  token.get7D = tokenAcala.get7D + acaToDot * tokenAcalaAca.get7D;

  return [token];
};

const loadCrossKsm = async function (
  apiKarura,
  apiBifrost,
  kusdPrice,
  ksmPrice
) {
  const addressKarura = "ooXeaKGqTAkTcPacksVjg8o49pJHyHoNaQ6nNxLa5EhDbnH";
  const addressBifrost = "cED8bEU4sRJ9Vaeoh7SysbwmBXiBc2kzhHqZ1fjeN3iepZU";
  // const addressBifrostT = "ebAen8GFahWBwfEeNAyAmVS9gbnPzMAtMXLop2tPH6qcU1t";
  // const endPointKarura = "wss://karura-rpc-2.aca-api.network/ws";
  // const endPointBifrost = "wss://bifrost-rpc.liebi.com/ws";

  // const apiKarura = await ApiPromise.create({
  //   provider: new WsProvider(endPointKarura),
  // });

  // const apiBifrost = await ApiPromise.create({
  //   provider: new WsProvider(endPointBifrost),
  // });
  let balance = 0;
  let balanceD1 = 0;
  let balanceD7 = 0;

  // let kusdPrice = await getPrice("acala-dollar");
  // let ksmPrice = await getPrice("kusama");

  let rate = ksmPrice / kusdPrice;

  const kusdBalance_karura = await getBalanceAcala(
    apiKarura,
    "KUSD",
    addressKarura,
    0,
    12
  );
  const kusdBalanceD1_karura = await getBalanceAcala(
    apiKarura,
    "KUSD",
    addressKarura,
    1,
    12
  );
  const kusdBalanceD7_karura = await getBalanceAcala(
    apiKarura,
    "KUSD",
    addressKarura,
    7,
    12
  );

  const ksmBalance_karura = await getBalanceAcala(
    apiKarura,
    "KSM",
    addressKarura,
    0,
    12
  );
  const ksmBalanceD1_karura = await getBalanceAcala(
    apiKarura,
    "KSM",
    addressKarura,
    1,
    12
  );
  const ksmBalanceD7_karura = await getBalanceAcala(
    apiKarura,
    "KSM",
    addressKarura,
    7,
    12
  );
  balance = balance + kusdBalance_karura / rate + ksmBalance_karura;
  balanceD1 = balanceD1 + kusdBalanceD1_karura / rate + ksmBalanceD1_karura;
  balanceD7 = balanceD7 + kusdBalanceD7_karura / rate + ksmBalanceD7_karura;

  //---------------------------------------------
  //---------------------------------------------
  const kusdBalance = await getBalanceBifrost(
    apiBifrost,
    "Stable",
    "KUSD",
    addressBifrost,
    0,
    12
  );
  const kusdBalanceD1 = await getBalanceBifrost(
    apiBifrost,
    "Stable",
    "KUSD",
    addressBifrost,
    1,
    12
  );
  const kusdBalanceD7 = await getBalanceBifrost(
    apiBifrost,
    "Stable",
    "KUSD",
    addressBifrost,
    7,
    12
  );

  const ksmBalance = await getBalanceBifrost(
    apiBifrost,
    "Token",
    "KSM",
    addressBifrost,
    0,
    12
  );
  const ksmBalanceD1 = await getBalanceBifrost(
    apiBifrost,
    "Token",
    "KSM",
    addressBifrost,
    1,
    12
  );
  const ksmBalanceD7 = await getBalanceBifrost(
    apiBifrost,
    "Token",
    "KSM",
    addressBifrost,
    7,
    12
  );
  balance = balance + kusdBalance / rate + ksmBalance;
  balanceD1 = balanceD1 + kusdBalanceD1 / rate + ksmBalanceD1;
  balanceD7 = balanceD7 + kusdBalanceD7 / rate + ksmBalanceD7;

  let token = {};
  token.balance = balance;
  token.price = ksmPrice;
  token.balanceUsd = token.balance * token.price;
  token.onlinedate = "10/5/2023";

  token.get1D =
    balance - (balanceD1 == 0 || isNaN(balanceD1) ? balance : balanceD1);
  token.get7D =
    balance - (balanceD7 == 0 || isNaN(balanceD7) ? balance : balanceD7);

  return token;
};

const loadHydraDx = async function (apiBifrost, api, dotPrice) {
  let address = "5DfeoZPWce34bne41Vhc2UZUEL9cY55etxURTJyRSEeidEQ6";

  let PoolStable = await apiBifrost.query.stableAsset.pools(0);
  let tokenRateCaches = await apiBifrost.query.stableAsset.tokenRateCaches(0, {
    VToken2: 0,
  });
  PoolStable = PoolStable.toJSON();
  let StableA = PoolStable.a / 100;
  let StableD = Number(PoolStable.totalSupply);
  let StableDot = Number(PoolStable.balances[0]);
  let StablesDot = Number(PoolStable.balances[1]);
  let StableRate = getStableprice(StableA, StableD, StableDot, StablesDot);
  tokenRateCaches = tokenRateCaches.toJSON();
  let BaseRate = tokenRateCaches[1] / tokenRateCaches[0];
  let C1price = BaseRate / StableRate;

  let balanceDot = await getBalanceHydra(api, 5, address, 0);
  let balanceD1Dot = await getBalanceHydra(api, 5, address, 1);
  let balanceD7Dot = await getBalanceHydra(api, 5, address, 7);

  let balanceSdot = await getBalanceHydra(api, 15, address, 0);
  let balanceD1Sdot = await getBalanceHydra(api, 15, address, 1);
  let balanceD7Sdot = await getBalanceHydra(api, 15, address, 7);

  let balance = balanceDot + balanceSdot * C1price;
  let balanceD1 = balanceD1Dot + balanceD1Sdot * C1price;
  let balanceD7 = balanceD7Dot + balanceD7Sdot * C1price;

  let token = {};
  token.balance = balance;
  token.price = dotPrice;
  token.balanceUsd = token.balance * token.price;
  token.onlinedate = "4/7/2024";

  token.get1D =
    balance - (balanceD1 == 0 || isNaN(balanceD1) ? balance : balanceD1);
  token.get7D =
    balance - (balanceD7 == 0 || isNaN(balanceD7) ? balance : balanceD7);

  return [token];
};

const loadInterlay = async function (api, btcPrice, dotPrice, usdtPrice) {
  const address1 = "5CLsTunkwRkTLwuCHMZuTJV8dbwhZAVtD6WpjMWk5hy4kQ8V";
  const address2 = "5DhsJLJeJ9i8gGsLtKGeRpPb5BWtJSgejzefSkY5UxNKGkZN";
  // Some mnemonic phrase
  // const wsProvider = new WsProvider("wss://api.interlay.io:443/parachain");
  // const api = await ApiPromise.create({ provider: wsProvider });

  let JsonDOT = { token: 0 };
  let JsonBTC = { token: "IBTC" };
  let JsonUSDT = { foreignasset: 2 };

  let balanceDot1 = await getBalanceInterlay(api, address1, JsonDOT, 0);
  let balanceDot1D1 = await getBalanceInterlay(api, address1, JsonDOT, 1);
  let balanceDot1D7 = await getBalanceInterlay(api, address1, JsonDOT, 7);

  let balanceBtc1 = await getBalanceInterlay(api, address1, JsonBTC, 0, 8);
  let balanceBtc1D1 = await getBalanceInterlay(api, address1, JsonBTC, 1, 8);
  let balanceBtc1D7 = await getBalanceInterlay(api, address1, JsonBTC, 7, 8);

  let balanceUsdt2 = await getBalanceInterlay(api, address2, JsonUSDT, 0, 6);
  let balanceUsdt2D1 = await getBalanceInterlay(api, address2, JsonUSDT, 1, 6);
  let balanceUsdt2D7 = await getBalanceInterlay(api, address2, JsonUSDT, 7, 6);

  let balanceBtc2 = await getBalanceInterlay(api, address2, JsonBTC, 0, 8);
  let balanceBtc2D1 = await getBalanceInterlay(api, address2, JsonBTC, 1, 8);
  let balanceBtc2D7 = await getBalanceInterlay(api, address2, JsonBTC, 7, 8);

  // let btcPrice = await getPrice("bitcoin");
  // let dotPrice = await getPrice("polkadot");
  // let usdtPrice = 1;

  let balance1 = (balanceDot1 * dotPrice) / btcPrice + balanceBtc1;
  let balance1D1 = (balanceDot1D1 * dotPrice) / btcPrice + balanceBtc1D1;
  let balance1D7 = (balanceDot1D7 * dotPrice) / btcPrice + balanceBtc1D7;

  let balance2 = (balanceUsdt2 * usdtPrice) / btcPrice + balanceBtc2;
  let balance2D1 = (balanceUsdt2D1 * usdtPrice) / btcPrice + balanceBtc2D1;
  let balance2D7 = (balanceUsdt2D7 * usdtPrice) / btcPrice + balanceBtc2D7;

  let token1 = {};

  token1.balance = balance1;
  token1.price = btcPrice;
  token1.balanceUsd = token1.balance * token1.price;
  token1.onlinedate = "10/15/2023";

  token1.get1D =
    balance1 - (balance1D1 == 0 || isNaN(balance1D1) ? balance1 : balance1D1);
  token1.get7D =
    balance1 - (balance1D7 == 0 || isNaN(balance1D7) ? balance1 : balance1D7);

  let token2 = {};

  token2.balance = balance2;
  token2.price = btcPrice;
  token2.balanceUsd = token2.balance * token2.price;
  token2.onlinedate = "10/15/2023";

  token2.get1D =
    balance2 - (balance2D1 == 0 || isNaN(balance2D1) ? balance2 : balance2D1);
  token2.get7D =
    balance2 - (balance2D7 == 0 || isNaN(balance2D7) ? balance2 : balance2D7);

  return [token1, token2];
};

const loadInterlayRef = async function (api, btcPrice, dotPrice) {
  const address1 = "5H6VapMkXJA15KgNCdvSHJvHwBgG529y66CjT6EgyVGk51BE"; //
  const address2 = "5CcffutrGwADNefQm9uMBkUGxuwVcvep6ZFB8CDyumz2DzjS";
  // Some mnemonic phrase
  // const wsProvider = new WsProvider("wss://api.interlay.io:443/parachain");
  // const api = await ApiPromise.create({ provider: wsProvider });

  let JsonDOT = { token: 0 };
  let JsonBTC = { token: "IBTC" };
  let JsonUSDT = { foreignasset: 2 };

  let balanceDot1 = await getBalanceInterlay(api, address1, JsonDOT, 0);
  let balanceDot1D1 = await getBalanceInterlay(api, address1, JsonDOT, 1);
  let balanceDot1D7 = await getBalanceInterlay(api, address1, JsonDOT, 7);

  let balanceBtc1 = await getBalanceInterlay(api, address1, JsonBTC, 0, 8);
  let balanceBtc1D1 = await getBalanceInterlay(api, address1, JsonBTC, 1, 8);
  let balanceBtc1D7 = await getBalanceInterlay(api, address1, JsonBTC, 7, 8);

  let balanceUsdt2 = await getBalanceInterlay(api, address2, JsonUSDT, 0, 6);
  let balanceUsdt2D1 = await getBalanceInterlay(api, address2, JsonUSDT, 1, 6);
  let balanceUsdt2D7 = await getBalanceInterlay(api, address2, JsonUSDT, 7, 6);

  let balanceBtc2 = await getBalanceInterlay(api, address2, JsonBTC, 0, 8);
  let balanceBtc2D1 = await getBalanceInterlay(api, address2, JsonBTC, 1, 8);
  let balanceBtc2D7 = await getBalanceInterlay(api, address2, JsonBTC, 7, 8);

  // let btcPrice = await getPrice("bitcoin");
  // let dotPrice = await getPrice("polkadot");

  let balance1 = balanceDot1 * dotPrice + balanceBtc1 * btcPrice;
  let balance1D1 = balanceDot1D1 * dotPrice + balanceBtc1D1 * btcPrice;
  let balance1D7 = balanceDot1D7 * dotPrice + balanceBtc1D7 * btcPrice;

  let balance2 = balanceUsdt2 + balanceBtc2 * btcPrice;
  let balance2D1 = balanceUsdt2D1 + balanceBtc2D1 * btcPrice;
  let balance2D7 = balanceUsdt2D7 + balanceBtc2D7 * btcPrice;

  let token1 = {};

  token1.balance = balance1;
  token1.price = 1;
  token1.balanceUsd = token1.balance * token1.price;
  token1.onlinedate = "11/09/2023";

  token1.get1D =
    balance1 - (balance1D1 == 0 || isNaN(balance1D1) ? balance1 : balance1D1);
  token1.get7D =
    balance1 - (balance1D7 == 0 || isNaN(balance1D7) ? balance1 : balance1D7);

  let token2 = {};

  token2.balance = balance2;
  token2.price = 1;
  token2.balanceUsd = token2.balance * token2.price;
  token2.onlinedate = "11/09/2023";

  token2.get1D =
    balance2 - (balance2D1 == 0 || isNaN(balance2D1) ? balance2 : balance2D1);
  token2.get7D =
    balance2 - (balance2D7 == 0 || isNaN(balance2D7) ? balance2 : balance2D7);

  return [token1, token2];
};

async function acalaLoadAddress(api, address, onlineDate, token, dotPrice) {
  const balance = await getBalanceAcala(api, "DOT", address, 0);
  const balanceD1 = await getBalanceAcala(api, "DOT", address, 1);
  const balanceD7 = await getBalanceAcala(api, "DOT", address, 7);

  // const dotPrice = await getPrice("polkadot");

  token.balance = balance;
  token.price = dotPrice;
  token.balanceUsd = token.balance * token.price;

  token.onlinedate = onlineDate;

  token.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
  token.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);
}

async function acalaLoadAddressBalance(api, address, token) {
  const balance = await getBalanceAcala(api, "DOT", address, 0);
  const balanceD1 = await getBalanceAcala(api, "DOT", address, 1);
  const balanceD7 = await getBalanceAcala(api, "DOT", address, 7);
  token.balance = balance;
  token.balanceD1 = balanceD1;
  token.balanceD7 = balanceD7;
}

async function acalaLoadAddressLcdot(api, address, token) {
  const balance = await getBalanceAcalaLcdot(api, address, 0);
  const balanceD1 = await getBalanceAcalaLcdot(api, address, 1);
  const balanceD7 = await getBalanceAcalaLcdot(api, address, 7);
  token.balance = balance;
  token.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
  token.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);
}

async function acalaLoadAddressLdotBalance(api, address, token) {
  const balance = await getBalanceAcalaLdot(api, address, 0);
  const balanceD1 = await getBalanceAcalaLdot(api, address, 1);
  const balanceD7 = await getBalanceAcalaLdot(api, address, 7);
  token.balance = balance;
  token.balanceD1 = balanceD1;
  token.balanceD7 = balanceD7;
}

async function acalaLoadAddressLdot(api, address, token) {
  const balance = await getBalanceAcalaLdot(api, address, 0);
  const balanceD1 = await getBalanceAcalaLdot(api, address, 1);
  const balanceD7 = await getBalanceAcalaLdot(api, address, 7);
  token.balance = balance;
  token.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
  token.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);
}

async function openSeaS1(provider, ethPrice) {
  const myaddress = "0xBE1Fa4eeab67A64922d10e43238BbDd82b6f0441";
  const wethAddress =
    "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619".toLowerCase();

  const collectionAddress =
    "0x24A11e702CD90f034Ea44FaF1e180C0C654AC5d9".toLowerCase();
  const fee = 0.1 + 0.025;
  // let response = await axios.get(
  //   "https://api.coingecko.com/api/v3/nfts/polygon-pos/contract/0x24A11e702CD90f034Ea44FaF1e180C0C654AC5d9"
  // );

  let response = await axios.get("https://kunkuicryptodata.com:18081/trump1");

  let floorPrice = Number(response.data);

  let consensusprice = floorPrice * (1 - fee / 2);
  // 获取weth账户余额

  const wethContract = new ethers.Contract(
    wethAddress,
    [
      "function balanceOf(address user) public view returns (uint)",
      "function approve(address a, uint amount) public",
    ],
    provider
  );

  let blockD1 = await getMaticBlock(provider, 1);
  let blockD7 = await getMaticBlock(provider, 7);

  let wethbalance = (await wethContract.balanceOf(myaddress)) / 1e18;

  let wethbalanceD1 =
    (await wethContract.balanceOf(myaddress, {
      blockTag: blockD1,
    })) / 1e18;

  let wethbalanceD7 =
    (await wethContract.balanceOf(myaddress, {
      blockTag: blockD7,
    })) / 1e18;

  // let wethNFTbalance = wethbalance / consensusprice;

  // 获取 NFT 账户余额
  let collectionContract = new ethers.Contract(
    collectionAddress,
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );
  let collectionNFTbalance = Number(
    await collectionContract.balanceOf(myaddress)
  );

  let collectionNFTbalanceD1 = Number(
    await collectionContract.balanceOf(myaddress, {
      blockTag: blockD1,
    })
  );
  let collectionNFTbalanceD7 = Number(
    await collectionContract.balanceOf(myaddress, {
      blockTag: blockD7,
    })
  );

  let collectionbalance = collectionNFTbalance * consensusprice;
  let collectionbalanceD1 = collectionNFTbalanceD1 * consensusprice;
  let collectionbalanceD7 = collectionNFTbalanceD7 * consensusprice;

  // const ethPrice = await getPrice("ethereum");
  // // console.log("balance in NFT:" + (wethNFTbalance + collectionNFTbalance));

  let balance =
    (wethbalance ? wethbalance : 0) +
    (collectionbalance ? collectionbalance : 0);
  let balanceD1 =
    (wethbalanceD1 ? wethbalanceD1 : 0) +
    (collectionbalanceD1 ? collectionbalanceD1 : 0);
  let balanceD7 =
    (wethbalanceD7 ? wethbalanceD7 : 0) +
    (collectionbalanceD7 ? collectionbalanceD7 : 0);

  let token = {};

  token.balance = balance;

  token.price = ethPrice;
  token.balanceUsd = token.balance * ethPrice;

  token.onlinedate = "1/15/2024";

  token.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
  token.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);

  return token;
}

async function openSeaS2(provider, ethPrice) {
  const myaddress = "0xD61d8428eafDCf97767dEA77cd05380e06A33125";
  const wethAddress =
    "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619".toLowerCase();

  const collectionAddress =
    "0xE28D2D8746D855251BA677a91626009CB33aA4F9".toLowerCase();
  const fee = 0.1 + 0.025;

  // let response = await axios.get(
  //   "https://api.coingecko.com/api/v3/nfts/polygon-pos/contract/0xE28D2D8746D855251BA677a91626009CB33aA4F9"
  // );

  let response = await axios.get("https://kunkuicryptodata.com:18081/trump2");
  let floorPrice = Number(response.data);

  let consensusprice = floorPrice * (1 - fee / 2);
  // 获取weth账户余额

  const wethContract = new ethers.Contract(
    wethAddress,
    [
      "function balanceOf(address user) public view returns (uint)",
      "function approve(address a, uint amount) public",
    ],
    provider
  );

  let blockD1 = await getMaticBlock(provider, 1);
  let blockD7 = await getMaticBlock(provider, 7);

  let wethbalance = (await wethContract.balanceOf(myaddress)) / 1e18;

  let wethbalanceD1 =
    (await wethContract.balanceOf(myaddress, {
      blockTag: blockD1,
    })) / 1e18;

  let wethbalanceD7 =
    (await wethContract.balanceOf(myaddress, {
      blockTag: blockD7,
    })) / 1e18;

  // let wethNFTbalance = wethbalance / consensusprice;

  // 获取 NFT 账户余额
  let collectionContract = new ethers.Contract(
    collectionAddress,
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );
  let collectionNFTbalance = Number(
    await collectionContract.balanceOf(myaddress)
  );

  let collectionNFTbalanceD1 = Number(
    await collectionContract.balanceOf(myaddress, {
      blockTag: blockD1,
    })
  );
  let collectionNFTbalanceD7 = Number(
    await collectionContract.balanceOf(myaddress, {
      blockTag: blockD7,
    })
  );

  let collectionbalance = collectionNFTbalance * consensusprice;
  let collectionbalanceD1 = collectionNFTbalanceD1 * consensusprice;
  let collectionbalanceD7 = collectionNFTbalanceD7 * consensusprice;

  // const ethPrice = await getPrice("ethereum");
  // // console.log("balance in NFT:" + (wethNFTbalance + collectionNFTbalance));

  let balance =
    (wethbalance ? wethbalance : 0) +
    (collectionbalance ? collectionbalance : 0);
  let balanceD1 =
    (wethbalanceD1 ? wethbalanceD1 : 0) +
    (collectionbalanceD1 ? collectionbalanceD1 : 0);
  let balanceD7 =
    (wethbalanceD7 ? wethbalanceD7 : 0) +
    (collectionbalanceD7 ? collectionbalanceD7 : 0);

  let token = {};

  token.balance = balance;

  token.price = ethPrice;
  token.balanceUsd = token.balance * ethPrice;

  token.onlinedate = "9/10/2023";

  token.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
  token.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);

  return token;
}

async function openSeaS3(provider, ethPrice) {
  const myaddress = "0x3573F2360E2E47665E5785987bf1Ea88DEb243d1";
  const wethAddress =
    "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619".toLowerCase();
  const collectionAddress =
    "0x9d305a42A3975Ee4c1C57555BeD5919889DCE63F".toLowerCase();
  const fee = 0.05 + 0.025;

  // let response = await axios.get("http://64.226.64.18:8080/");
  let response = await axios.get("https://kunkuicryptodata.com:18081/nft");

  let floorPrice = Number(response.data) / 1e18;

  let consensusprice = floorPrice * (1 - fee / 2);

  // 获取weth账户余额
  const wethContract = new ethers.Contract(
    wethAddress,
    [
      "function balanceOf(address user) public view returns (uint)",
      "function approve(address a, uint amount) public",
    ],
    provider
  );

  let blockD1 = await getMaticBlock(provider, 1);
  let blockD7 = await getMaticBlock(provider, 7);

  let wethbalance = (await wethContract.balanceOf(myaddress)) / 1e18;

  let wethbalanceD1 =
    (await wethContract.balanceOf(myaddress, {
      blockTag: blockD1,
    })) / 1e18;

  let wethbalanceD7 =
    (await wethContract.balanceOf(myaddress, {
      blockTag: blockD7,
    })) / 1e18;

  // let wethNFTbalance = wethbalance / consensusprice;

  // 获取 NFT 账户余额
  let collectionContract = new ethers.Contract(
    collectionAddress,
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );
  let collectionNFTbalance = Number(
    await collectionContract.balanceOf(myaddress)
  );

  let collectionNFTbalanceD1 = Number(
    await collectionContract.balanceOf(myaddress, {
      blockTag: blockD1,
    })
  );
  let collectionNFTbalanceD7 = Number(
    await collectionContract.balanceOf(myaddress, {
      blockTag: blockD7,
    })
  );

  let collectionbalance = collectionNFTbalance * consensusprice;
  let collectionbalanceD1 = collectionNFTbalanceD1 * consensusprice;
  let collectionbalanceD7 = collectionNFTbalanceD7 * consensusprice;

  // const ethPrice = await getPrice("ethereum");
  // // console.log("balance in NFT:" + (wethNFTbalance + collectionNFTbalance));

  let balance =
    (wethbalance ? wethbalance : 0) +
    (collectionbalance ? collectionbalance : 0);
  let balanceD1 =
    (wethbalanceD1 ? wethbalanceD1 : 0) +
    (collectionbalanceD1 ? collectionbalanceD1 : 0);
  let balanceD7 =
    (wethbalanceD7 ? wethbalanceD7 : 0) +
    (collectionbalanceD7 ? collectionbalanceD7 : 0);

  let token = {};

  token.balance = balance;

  token.price = ethPrice;
  token.balanceUsd = token.balance * ethPrice;

  token.onlinedate = "9/10/2023";

  token.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
  token.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);

  return token;
}

// async function zerion() {
//   let balanceUrl1 =
//     "https://api.zerion.io/v1/wallets/0xA9aA11Ff27dB5ca5364c56CdB323c02Ba1c78Abe/portfolio?currency=usd";
//   let response = await fetch(balanceUrl1, {
//     headers: {
//       accept: "application/json",
//       authorization:
//         "Basic emtfZGV2X2MwOTRkYjExMTk4MjRmMGNhNWY0OWE0MWVhNzNlZDhkOg==",
//     },
//   });
//   console.log("111111111111111111111111111111");
//   let token1 = {};
//   let token2 = {};

//   token1.balance = Number(
//     response.data.data.attributes.positions_distribution_by_chain.optimism
//   );

//   token1.price = 1;
//   token1.balanceUsd = token1.balance;
//   token1.onlinedate = "1/17/2023";
//   token1.get1D = 0;
//   token1.get7D = 0;

//   token2.balance = Number(
//     response.data.data.attributes.positions_distribution_by_chain.polygon
//   );
//   token2.price = 1;
//   token2.balanceUsd = token2.balance;
//   token2.onlinedate = "1/17/2023";
//   token2.get1D = 0;
//   token2.get7D = 0;

//   let tokens = [token1, token2];
//   return tokens;
// }

async function treasureOP(provider, ethPrice) {
  let myaddress = "0xA9aA11Ff27dB5ca5364c56CdB323c02Ba1c78Abe";
  const tokenUSDT = new ethers.Contract(
    "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  const tokenUSDC = new ethers.Contract(
    "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  let usdtBalance = Number(await tokenUSDT.balanceOf(myaddress)) / 1e6;
  let usdcBalance = Number(await tokenUSDC.balanceOf(myaddress)) / 1e6;
  let ethBalance = Number(await provider.getBalance(myaddress)) / 1e18;

  let token = {};
  token.balance = usdtBalance + usdcBalance + ethBalance * ethPrice;
  token.price = 1;
  token.balanceUsd = token.balance;
  token.onlinedate = "1/17/2023";
  token.get1D = 0;
  token.get7D = 0;

  return token;
}

async function treasureMatic(provider, ethPrice) {
  let myaddress = "0xA9aA11Ff27dB5ca5364c56CdB323c02Ba1c78Abe";

  const tokenETH = new ethers.Contract(
    "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  const tokenUSDT = new ethers.Contract(
    "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  const tokenUSDC = new ethers.Contract(
    "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  let ethBalance = Number(await tokenETH.balanceOf(myaddress)) / 1e18;
  let usdtBalance = Number(await tokenUSDT.balanceOf(myaddress)) / 1e6;
  let usdcBalance = Number(await tokenUSDC.balanceOf(myaddress)) / 1e6;

  let token = {};
  token.balance = usdtBalance + usdcBalance + ethBalance * ethPrice;
  token.price = 1;
  token.balanceUsd = token.balance;
  token.onlinedate = "1/17/2023";
  token.get1D = 0;
  token.get7D = 0;

  return token;
}

async function treasureBTC(price) {
  let balanceUrl =
    "https://mempool.space/api/address//bc1qwag9havrrpv74wvprt7652h2vfc75s7fva33yf";
  let response = await axios.get(balanceUrl, {
    headers: {
      accept: "application/json",
    },
  });

  let token = {};

  let balance =
    (Number(response.data.chain_stats.funded_txo_sum) -
      Number(response.data.chain_stats.spent_txo_sum)) /
    1e8;

  token.balance = balance;
  token.price = price;
  token.balanceUsd = token.balance * token.price;
  token.onlinedate = "3/21/2023";
  token.get1D = 0;
  token.get7D = 0;

  return token;
}

async function btcNft(price) {
  let balanceUrl =
    "https://mempool.space/api/address/bc1pycsgpnslghwx7w7lup88mjqh6pqf4nge92d7v678pw9dem9uaeqqa52hc9";
  let response = await axios.get(balanceUrl, {
    headers: {
      accept: "application/json",
    },
  });

  let responsePast = await axios.get("https://kunkuicryptodata.com:18081/btc");

  let myBalance = responsePast.data;

  let balanceD1 = Number(myBalance.day1Balance);
  let balanceD7 = Number(myBalance.day7Balance);

  let token = {};

  let balance =
    (Number(response.data.chain_stats.funded_txo_sum) -
      Number(response.data.chain_stats.spent_txo_sum)) /
    1e8;

  token.balance = balance;
  token.price = price;
  token.balanceUsd = token.balance * token.price;
  token.onlinedate = "1/17/2023";
  token.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
  token.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);

  return token;
}

async function parallelLoadAddress(api, address, onlineDate, token, dotPrice) {
  const balance = await getBalancePara(api, 101, address, 0);
  const balanceD1 = await getBalancePara(api, 101, address, 1);
  const balanceD7 = await getBalancePara(api, 101, address, 7);

  // const dotPrice = await getPrice("polkadot");

  token.balance = balance;
  token.price = dotPrice;
  token.balanceUsd = token.balance * token.price;

  token.onlinedate = onlineDate;

  token.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
  token.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);
}

async function parallelLoadAddressBalance(api, address, token) {
  const balance = await getBalancePara(api, 101, address, 0);
  const balanceD1 = await getBalancePara(api, 101, address, 1);
  const balanceD7 = await getBalancePara(api, 101, address, 7);
  token.balance = balance;
  token.balanceD1 = balanceD1;
  token.balanceD7 = balanceD7;
}

async function paraLoadAddressLcdot(api, address, token) {
  const balance = await getBalancePara(api, 106, address, 0);
  const balanceD1 = await getBalancePara(api, 106, address, 1);
  const balanceD7 = await getBalancePara(api, 106, address, 7);
  token.balance = balance;
  token.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
  token.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);
}

async function parallelLoadAddressLdot(api, address, token) {
  const balance = await getBalancePara(api, 110, address, 0);
  const balanceD1 = await getBalancePara(api, 110, address, 1);
  const balanceD7 = await getBalancePara(api, 110, address, 7);
  token.balance = balance;
  token.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
  token.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);
}

async function parallelLoadAddressLdotBalance(api, address, token) {
  const balance = await getBalancePara(api, 110, address, 0);
  const balanceD1 = await getBalancePara(api, 110, address, 1);
  const balanceD7 = await getBalancePara(api, 110, address, 7);
  token.balance = balance;
  token.balanceD1 = balanceD1;
  token.balanceD7 = balanceD7;
}

const loadKarura = async function (api, kusdPrice) {
  // Construct
  const addressHelsinki = "5HjffkdzwnnCmvoaV2Mz2Lu3uDfRbQM77YERUMhdWruuCwhu";
  const addressUS = "qa7e6E9dM6ZwnMrtnJLuQXMNwAi7HhtW9qRfz6vxxBnHr9V";

  let tokenHelsinki = {};
  let tokenUS = {};

  await karuraLoadAddress(
    api,
    addressHelsinki,
    "8/18/2022",
    tokenHelsinki,
    kusdPrice
  );
  await karuraLoadAddress(api, addressUS, "5/28/2024", tokenUS, kusdPrice);

  return [tokenHelsinki, tokenUS];
};

async function karuraLoadAddress(api, address, onlineDate, token, tokenPrice) {
  const balance = await getBalanceAcala(api, "KUSD", address, 0, 12);
  const balanceD1 = await getBalanceAcala(api, "KUSD", address, 1, 12);
  const balanceD7 = await getBalanceAcala(api, "KUSD", address, 7, 12);

  // const tokenPrice = await getPrice("acala-dollar");

  token.balance = balance;
  token.price = tokenPrice;
  token.balanceUsd = token.balance * token.price;

  token.onlinedate = onlineDate;

  token.get1D = balance - (balanceD1 == 0 ? balance : balanceD1);
  token.get7D = balance - (balanceD7 == 0 ? balance : balanceD7);
}

async function loadMode(provider, tokenPrice) {
  let myaddress1 = "0x5E44489edb069786F485E02dD19efA52764b0ae6";
  let myaddress2 = "0xFcAAa6f52bEf837bdEF69C5ff116896E06FBC212";
  let myaddress3 = "0x0B2613B4B42C30fdD7ca0D68a4f0cfe78725143a";
  let myaddress4 = "0xB965c897CBfff1b47198C9Fb11C56453cFdB94de";
  let myaddress5 = "0x7d2Eb8CD2962037AaEDdE48BbEb8C908a7F4345a";
  let myaddress6 = "0x7Ebff84B67A3dCb916216717F1e543D90526B049";
  let myaddress7 = "0xF67ABFaaeC8DAD67380F190504049405aaDED88B";

  const tokenUSDC = new ethers.Contract(
    "0xd988097fb8612cc24eeC14542bC03424c656005f",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  const tokenUSDT = new ethers.Contract(
    "0xf0F161fDA2712DB8b566946122a5af183995e2eD",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  const tokenWeth = new ethers.Contract(
    "0x4200000000000000000000000000000000000006",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  let blockD1 = await getModeBlock(provider, 1);
  let blockD7 = await getModeBlock(provider, 7);

  let wethBalance = Number(await tokenWeth.balanceOf(myaddress1)) / 1e18;

  let wethBalanceD1 =
    (await tokenWeth.balanceOf(myaddress1, {
      blockTag: blockD1,
    })) / 1e18;
  let wethBalanceD7 =
    (await tokenWeth.balanceOf(myaddress1, {
      blockTag: blockD7,
    })) / 1e18;

  let token1 = {};
  token1.balance = wethBalance;
  token1.price = tokenPrice;
  token1.balanceUsd = token1.balance * tokenPrice;
  token1.onlinedate = "2/21/2024";
  token1.get1D =
    (wethBalance - (wethBalanceD1 == 0 ? wethBalance : wethBalanceD1)) *
    tokenPrice;
  token1.get7D =
    (wethBalance - (wethBalanceD7 == 0 ? wethBalance : wethBalanceD7)) *
    tokenPrice;
  ////////////////////////////////////////////

  let usdcBalance2 =
    Number(await tokenUSDC.balanceOf(myaddress2)) / 1e6 +
    Number(await tokenUSDT.balanceOf(myaddress2)) / 1e6;

  let usdcBalance2D1 =
    (await tokenUSDC.balanceOf(myaddress2, {
      blockTag: blockD1,
    })) /
      1e6 +
    (await tokenUSDT.balanceOf(myaddress2, {
      blockTag: blockD1,
    })) /
      1e6;

  let usdcBalance2D7 =
    (await tokenUSDC.balanceOf(myaddress2, {
      blockTag: blockD7,
    })) /
      1e6 +
    (await tokenUSDT.balanceOf(myaddress2, {
      blockTag: blockD7,
    })) /
      1e6;

  let token2 = {};
  token2.balance = usdcBalance2;
  token2.price = 1;
  token2.balanceUsd = token2.balance;
  token2.onlinedate = "3/2/2024";
  token2.get1D =
    usdcBalance2 - (usdcBalance2D1 == 0 ? usdcBalance2 : usdcBalance2D1);
  token2.get7D =
    usdcBalance2 - (usdcBalance2D7 == 0 ? usdcBalance2 : usdcBalance2D7);

  ////////////////////////////////////////////

  let usdcBalance3 =
    Number(await tokenUSDC.balanceOf(myaddress3)) / 1e6 +
    Number(await tokenUSDT.balanceOf(myaddress3)) / 1e6;

  let usdcBalance3D1 =
    (await tokenUSDC.balanceOf(myaddress3, {
      blockTag: blockD1,
    })) /
      1e6 +
    (await tokenUSDT.balanceOf(myaddress3, {
      blockTag: blockD1,
    })) /
      1e6;

  let usdcBalance3D7 =
    (await tokenUSDC.balanceOf(myaddress3, {
      blockTag: blockD7,
    })) /
      1e6 +
    (await tokenUSDT.balanceOf(myaddress3, {
      blockTag: blockD7,
    })) /
      1e6;

  let token3 = {};
  token3.balance = usdcBalance3;
  token3.price = 1;
  token3.balanceUsd = token3.balance;
  token3.onlinedate = "3/16/2024";
  token3.get1D =
    usdcBalance3 - (usdcBalance3D1 == 0 ? usdcBalance3 : usdcBalance3D1);
  token3.get7D =
    usdcBalance3 - (usdcBalance3D7 == 0 ? usdcBalance3 : usdcBalance3D7);

  ////////////////////////////////////////////

  let wethBalance4 = Number(await tokenWeth.balanceOf(myaddress4)) / 1e18;

  let wethBalance4D1 =
    (await tokenWeth.balanceOf(myaddress4, {
      blockTag: blockD1,
    })) / 1e18;

  let wethBalance4D7 =
    (await tokenWeth.balanceOf(myaddress4, {
      blockTag: blockD7,
    })) / 1e18;

  let token4 = {};
  token4.balance = wethBalance4;
  token4.price = tokenPrice;
  token4.balanceUsd = token4.balance * tokenPrice;
  token4.onlinedate = "12/27/2024";
  token4.get1D =
    (wethBalance4 - (wethBalance4D1 == 0 ? wethBalance4 : wethBalance4D1)) *
    tokenPrice;
  token4.get7D =
    (wethBalance4 - (wethBalance4D7 == 0 ? wethBalance4 : wethBalance4D7)) *
    tokenPrice;
  ////////////////////////////////////////////

  let usdcBalance5 =
    Number(await tokenUSDC.balanceOf(myaddress5)) / 1e6 +
    Number(await tokenUSDT.balanceOf(myaddress5)) / 1e6;

  let usdcBalance5D1 =
    (await tokenUSDC.balanceOf(myaddress5, {
      blockTag: blockD1,
    })) /
      1e6 +
    (await tokenUSDT.balanceOf(myaddress5, {
      blockTag: blockD1,
    })) /
      1e6;

  let usdcBalance5D7 =
    (await tokenUSDC.balanceOf(myaddress5, {
      blockTag: blockD7,
    })) /
      1e6 +
    (await tokenUSDT.balanceOf(myaddress5, {
      blockTag: blockD7,
    })) /
      1e6;

  let token5 = {};
  token5.balance = usdcBalance5;
  token5.price = 1;
  token5.balanceUsd = token5.balance;
  token5.onlinedate = "3/16/2024";
  token5.get1D =
    usdcBalance5 - (usdcBalance5D1 == 0 ? usdcBalance5 : usdcBalance5D1);
  token5.get7D =
    usdcBalance5 - (usdcBalance5D7 == 0 ? usdcBalance5 : usdcBalance5D7);

  ////////////////////////////////////////////

  let usdcBalance6 =
    Number(await tokenUSDC.balanceOf(myaddress6)) / 1e6 +
    Number(await tokenUSDT.balanceOf(myaddress6)) / 1e6;

  let usdcBalance6D1 =
    (await tokenUSDC.balanceOf(myaddress6, {
      blockTag: blockD1,
    })) /
      1e6 +
    (await tokenUSDT.balanceOf(myaddress6, {
      blockTag: blockD1,
    })) /
      1e6;

  let usdcBalance6D7 =
    (await tokenUSDC.balanceOf(myaddress6, {
      blockTag: blockD7,
    })) /
      1e6 +
    (await tokenUSDT.balanceOf(myaddress6, {
      blockTag: blockD7,
    })) /
      1e6;

  let token6 = {};
  token6.balance = usdcBalance6;
  token6.price = 1;
  token6.balanceUsd = token6.balance;
  token6.onlinedate = "3/16/2024";
  token6.get1D =
    usdcBalance6 - (usdcBalance6D1 == 0 ? usdcBalance6 : usdcBalance6D1);
  token6.get7D =
    usdcBalance6 - (usdcBalance6D7 == 0 ? usdcBalance6 : usdcBalance6D7);

  ////////////////////////////////////////////

  let wethBalance7 = Number(await tokenWeth.balanceOf(myaddress7)) / 1e18;

  let wethBalance7D1 =
    (await tokenWeth.balanceOf(myaddress7, {
      blockTag: blockD1,
    })) / 1e18;

  let wethBalance7D7 =
    (await tokenWeth.balanceOf(myaddress7, {
      blockTag: blockD7,
    })) / 1e18;

  let token7 = {};
  token7.balance = wethBalance7;
  token7.price = tokenPrice;
  token7.balanceUsd = token7.balance * tokenPrice;
  token7.onlinedate = "1/8/2024";
  token7.get1D =
    (wethBalance7 - (wethBalance7D1 == 0 ? wethBalance7 : wethBalance7D1)) *
    tokenPrice;
  token7.get7D =
    (wethBalance7 - (wethBalance7D7 == 0 ? wethBalance7 : wethBalance7D7)) *
    tokenPrice;
  ////////////////////////////////////////////

  return [token1, token2, token3, token4, token5, token6, token7];
}

async function loadMerlin(provider, btcPrice) {
  let myaddress1 = "0x5Ab2764b19eA5969599DD1cF99feda7068e9332D";
  let myaddress2 = "0xe9682DC1EcE1af0809fA689f95209c9cd019e08f";

  const tokenWbtc = new ethers.Contract(
    "0xF6D226f9Dc15d9bB51182815b320D3fBE324e1bA",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  // const tokenIusd = new ethers.Contract(
  //   "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
  //   ["function balanceOf(address user) public view returns (uint)"],
  //   provider
  // );

  // const tokenMusdc = new ethers.Contract(
  //   "0x6b4ecada640f1b30dbdb68f77821a03a5f282ebe",
  //   ["function balanceOf(address user) public view returns (uint)"],
  //   provider
  // );

  // const tokenMusdt = new ethers.Contract(
  //   "0x967aEC3276b63c5E2262da9641DB9dbeBB07dC0d",
  //   ["function balanceOf(address user) public view returns (uint)"],
  //   provider
  // );

  // const tokenMbtc = new ethers.Contract(
  //   "0xB880fd278198bd590252621d4CD071b1842E9Bcd",
  //   ["function balanceOf(address user) public view returns (uint)"],
  //   provider
  // );

  let responsePast = await axios.get(
    "https://kunkuicryptodata.com:18081/merlin"
  );

  let myBalance = responsePast.data;

  let wbtcBalanceD1 = Number(myBalance.day1Balance1) / 1e18;
  let wbtcBalanceD7 = Number(myBalance.day7Balance1) / 1e18;
  let wbtcBalance2D1 = Number(myBalance.day1Balance2) / 1e18;
  let wbtcBalance2D7 = Number(myBalance.day7Balance2) / 1e18;

  let wbtcBalance = Number(await tokenWbtc.balanceOf(myaddress1)) / 1e18;
  let wbtcBalance2 = Number(await tokenWbtc.balanceOf(myaddress2)) / 1e18;

  //----------------------------------
  let token1 = {};
  token1.balance = wbtcBalance;
  token1.price = btcPrice;
  token1.balanceUsd = token1.balance * btcPrice;
  token1.onlinedate = "3/12/2024";
  token1.get1D =
    (wbtcBalance - (wbtcBalanceD1 == 0 ? wbtcBalance : wbtcBalanceD1)) *
    btcPrice;

  token1.get7D =
    (wbtcBalance - (wbtcBalanceD7 == 0 ? wbtcBalance : wbtcBalanceD7)) *
    btcPrice;
  //----------------------------------
  let token2 = {};
  token2.balance = wbtcBalance2;
  token2.price = btcPrice;
  token2.balanceUsd = token2.balance * btcPrice;
  token2.onlinedate = "3/12/2024";
  token2.get1D =
    (wbtcBalance2 - (wbtcBalance2D1 == 0 ? wbtcBalance2 : wbtcBalance2D1)) *
    btcPrice;

  token2.get7D =
    (wbtcBalance2 - (wbtcBalance2D7 == 0 ? wbtcBalance2 : wbtcBalance2D7)) *
    btcPrice;
  //----------------------------------
  return [token1, token2];
}

async function loadFrax(provider, ethPrice) {
  // v3 pool
  let myaddress1 = "0xDE2C7333aeFB4Ee798013816BF8e893088BABd79";
  //  treasure
  let myaddress2 = "0x419Da7519777FB8A7aB43BE87C88feeDD00D500B";
  // curve weth
  let myaddress3 = "0xf5D2a6DCe5b3a397f6452BB55Cd5F7F1367a9d25";
  // curve frax
  let myaddress4 = "0x4f38112047CBc19958a5316962cdCd904F9DC185";
  // balancer
  let myaddress5 = "0xF3290adcd1C7487267cbA46470ccBdd0e550C23F";
  // velo frax
  let myaddress6 = "0xE6Cf9A833457314fCb8ced1A79D492008a0873F8";
  // velo weth
  let myaddress7 = "0x1AEE227751D972d7aeC17C3A4eEFf5477D6a0CDE";

  const tokenWeth = new ethers.Contract(
    "0xFC00000000000000000000000000000000000006",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  const tokenFrax = new ethers.Contract(
    "0xFc00000000000000000000000000000000000001",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  let blockD1 = await getFraxtalBlock(provider, 1);
  let blockD7 = await getFraxtalBlock(provider, 7);

  let fraxBalance = Number(await tokenWeth.balanceOf(myaddress1)) / 1e18;

  let fraxBalanceD1 =
    (await tokenWeth.balanceOf(myaddress1, {
      blockTag: blockD1,
    })) / 1e18;

  let fraxBalanceD7 =
    (await tokenWeth.balanceOf(myaddress1, {
      blockTag: blockD7,
    })) / 1e18;

  let token1 = {};
  token1.balance = fraxBalance;
  token1.price = ethPrice;
  token1.balanceUsd = token1.balance * ethPrice;
  token1.onlinedate = "4/3/2024";
  token1.get1D =
    fraxBalance - (fraxBalanceD1 == 0 ? fraxBalance : fraxBalanceD1);

  token1.get7D =
    fraxBalance - (fraxBalanceD7 == 0 ? fraxBalance : fraxBalanceD7);

  // --------------------------
  let fraxBalance2 = Number(await tokenWeth.balanceOf(myaddress2)) / 1e18;

  let fraxBalance2D1 =
    (await tokenWeth.balanceOf(myaddress2, {
      blockTag: blockD1,
    })) / 1e18;

  let fraxBalance2D7 =
    (await tokenWeth.balanceOf(myaddress2, {
      blockTag: blockD7,
    })) / 1e18;

  let token2 = {};
  token2.balance = fraxBalance2;
  token2.price = ethPrice;
  token2.balanceUsd = token2.balance * ethPrice;
  token2.onlinedate = "4/3/2024";
  token2.get1D =
    fraxBalance2 - (fraxBalance2D1 == 0 ? fraxBalance2 : fraxBalance2D1);

  token2.get7D =
    fraxBalance2 - (fraxBalance2D7 == 0 ? fraxBalance2 : fraxBalance2D7);

  // --------------------------
  let fraxBalance3 = Number(await tokenWeth.balanceOf(myaddress3)) / 1e18;

  let fraxBalance3D1 =
    (await tokenWeth.balanceOf(myaddress3, {
      blockTag: blockD1,
    })) / 1e18;

  let fraxBalance3D7 =
    (await tokenWeth.balanceOf(myaddress3, {
      blockTag: blockD7,
    })) / 1e18;

  let token3 = {};
  token3.balance = fraxBalance3;
  token3.price = ethPrice;
  token3.balanceUsd = token3.balance * ethPrice;
  token3.onlinedate = "5/28/2024";
  token3.get1D =
    fraxBalance3 - (fraxBalance3D1 == 0 ? fraxBalance3 : fraxBalance3D1);

  token3.get7D =
    fraxBalance3 - (fraxBalance3D7 == 0 ? fraxBalance3 : fraxBalance3D7);
  // --------------------------
  let fraxBalance4 = Number(await tokenFrax.balanceOf(myaddress4)) / 1e18;
  let fraxBalance4D1 =
    (await tokenFrax.balanceOf(myaddress4, {
      blockTag: blockD1,
    })) / 1e18;

  let fraxBalance4D7 =
    (await tokenFrax.balanceOf(myaddress4, {
      blockTag: blockD7,
    })) / 1e18;

  let token4 = {};
  token4.balance = fraxBalance4;
  token4.price = 1;
  token4.balanceUsd = token4.balance;
  token4.onlinedate = "1/1/2025";

  token4.get1D =
    fraxBalance4 - (fraxBalance4D1 == 0 ? fraxBalance4 : fraxBalance4D1);
  token4.get7D =
    fraxBalance4 - (fraxBalance4D7 == 0 ? fraxBalance4 : fraxBalance4D7);

  // --------------------------
  let fraxBalance5 = Number(await tokenFrax.balanceOf(myaddress5)) / 1e18;
  let fraxBalance5D1 =
    (await tokenFrax.balanceOf(myaddress5, {
      blockTag: blockD1,
    })) / 1e18;

  let fraxBalance5D7 =
    (await tokenFrax.balanceOf(myaddress5, {
      blockTag: blockD7,
    })) / 1e18;

  let token5 = {};
  token5.balance = fraxBalance5;
  token5.price = 1;
  token5.balanceUsd = token5.balance;
  token5.onlinedate = "1/1/2025";

  token5.get1D =
    fraxBalance5 - (fraxBalance5D1 == 0 ? fraxBalance5 : fraxBalance5D1);
  token5.get7D =
    fraxBalance5 - (fraxBalance5D7 == 0 ? fraxBalance5 : fraxBalance5D7);

  // --------------------------
  let fraxBalance6 = Number(await tokenFrax.balanceOf(myaddress6)) / 1e18;
  let fraxBalance6D1 =
    (await tokenFrax.balanceOf(myaddress6, {
      blockTag: blockD1,
    })) / 1e18;

  let fraxBalance6D7 =
    (await tokenFrax.balanceOf(myaddress6, {
      blockTag: blockD7,
    })) / 1e18;

  let token6 = {};
  token6.balance = fraxBalance6;
  token6.price = 1;
  token6.balanceUsd = token6.balance;
  token6.onlinedate = "1/14/2025";

  token6.get1D =
    fraxBalance6 - (fraxBalance6D1 == 0 ? fraxBalance6 : fraxBalance6D1);
  token6.get7D =
    fraxBalance6 - (fraxBalance6D7 == 0 ? fraxBalance6 : fraxBalance6D7);

  // --------------------------
  let fraxBalance7 = Number(await tokenWeth.balanceOf(myaddress7)) / 1e18;

  let fraxBalance7D1 =
    (await tokenWeth.balanceOf(myaddress7, {
      blockTag: blockD1,
    })) / 1e18;

  let fraxBalance7D7 =
    (await tokenWeth.balanceOf(myaddress7, {
      blockTag: blockD7,
    })) / 1e18;

  let token7 = {};
  token7.balance = fraxBalance7;
  token7.price = ethPrice;
  token7.balanceUsd = token7.balance * ethPrice;
  token7.onlinedate = "1/14/2025";
  token7.get1D =
    fraxBalance7 - (fraxBalance7D1 == 0 ? fraxBalance7 : fraxBalance7D1);

  token7.get7D =
    fraxBalance7 - (fraxBalance7D7 == 0 ? fraxBalance7 : fraxBalance7D7);
  // --------------------------

  return [token1, token2, token3, token4, token5, token6, token7];
}

async function loadScroll(provider, ethPrice) {
  let myaddress1 = "0xF7A1F5a8740D68c15b1c3f81D410A201a5AbC228";

  const tokenWeth = new ethers.Contract(
    "0x5300000000000000000000000000000000000004",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  let blockD1 = await getFraxtalBlock(provider, 1); // the same as fraxtal 2 seconds
  let blockD7 = await getFraxtalBlock(provider, 7);

  // --------------------------
  let wethBalance1 = Number(await tokenWeth.balanceOf(myaddress1)) / 1e18;

  let wethBalance1D1 =
    (await tokenWeth.balanceOf(myaddress1, {
      blockTag: blockD1,
    })) / 1e18;

  let wethBalance1D7 =
    (await tokenWeth.balanceOf(myaddress1, {
      blockTag: blockD7,
    })) / 1e18;

  let token1 = {};
  token1.balance = wethBalance1;
  token1.price = ethPrice;
  token1.balanceUsd = token1.balance * ethPrice;
  token1.onlinedate = "test";
  token1.get1D =
    wethBalance1 - (wethBalance1D1 == 0 ? wethBalance1 : wethBalance1D1);

  token1.get7D =
    wethBalance1 - (wethBalance1D7 == 0 ? wethBalance1 : wethBalance1D7);

  // return [token1, token2, token3];
  return [token1];
}

async function loadB2(provider, btcPrice) {
  let myaddress1 = "0xb94440775B0A21f07098f8fdc9A4EdE03104b62a";
  // let myaddress2 = "0xb94440775B0A21f07098f8fdc9A4EdE03104b62a";

  const tokenWeth = new ethers.Contract(
    "0x4200000000000000000000000000000000000006",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  let blockD1 = await getFraxtalBlock(provider, 1); // the same as fraxtal 2 seconds
  let blockD7 = await getFraxtalBlock(provider, 7);

  let b2Balance = Number(await tokenWeth.balanceOf(myaddress1)) / 1e18;

  let b2BalanceD1 = 0;
  let b2BalanceD7 = 0;
  try {
    b2BalanceD1 =
      (await tokenWeth.balanceOf(myaddress1, {
        blockTag: blockD1,
      })) / 1e18;

    (await tokenWeth.balanceOf(myaddress1, {
      blockTag: blockD7,
    })) / 1e18;
  } catch (error) {}

  let token1 = {};
  token1.balance = b2Balance;
  token1.price = btcPrice;
  token1.balanceUsd = token1.balance * btcPrice;
  token1.onlinedate = "4/20/2024";
  token1.get1D =
    (b2Balance - (b2BalanceD1 == 0 ? b2Balance : b2BalanceD1)) * btcPrice;
  token1.get7D =
    (b2Balance - (b2BalanceD7 == 0 ? b2Balance : b2BalanceD7)) * btcPrice;

  // --------------------------
  // let b2Balance2 = Number(await tokenWeth.balanceOf(myaddress2)) / 1e18;

  // let b2Balance2D1 =
  //   (await tokenWeth.balanceOf(myaddress2, {
  //     blockTag: blockD1,
  //   })) / 1e18;

  // let b2Balance2D7 =
  //   (await tokenWeth.balanceOf(myaddress2, {
  //     blockTag: blockD7,
  //   })) / 1e18;

  // let token2 = {};
  // token2.balance = b2Balance2;
  // token2.price = btcPrice;
  // token2.balanceUsd = token2.balance * btcPrice;
  // token2.onlinedate = "4/20/2024";
  // token2.get1D =
  //   b2Balance2 - (b2Balance2D1 == 0 ? b2Balance2 : b2Balance2D1);

  // token2.get7D =
  //   b2Balance2 - (b2Balance2D7 == 0 ? b2Balance2 : b2Balance2D7);

  return [token1];
}

async function loadBob(provider, ethPrice, btcPrice) {
  let myaddress1 = "0xFcAAa6f52bEf837bdEF69C5ff116896E06FBC212";
  let myaddress2 = "0xC240B732B50B8aA2aa6503f6e18bfd2819c4Ca45";
  let myaddress3 = "0xdaf261C32c8a9cE560d3F09bE532E188107b99Fc";

  let myaddress4 = "0xacb665ca5e510C470938A3F9bb0EfAD760D62429";
  let myaddress5 = "0x12b2286C59C205C4f7514CC1cC323671F8610Be8";

  const tokenWeth = new ethers.Contract(
    "0x4200000000000000000000000000000000000006",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  const tokenWbtc = new ethers.Contract(
    "0x03C7054BCB39f7b2e5B2c7AcB37583e32D70Cfa3",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  const tokenUsdc = new ethers.Contract(
    "0xe75D0fB2C24A55cA1e3F96781a2bCC7bdba058F0",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  let blockD1 = await getFraxtalBlock(provider, 1);
  let blockD7 = await getFraxtalBlock(provider, 7);
  // ===================================================

  let bobBalance = Number(await tokenWeth.balanceOf(myaddress1)) / 1e18;

  let bobBalanceD1 =
    (await tokenWeth.balanceOf(myaddress1, {
      blockTag: blockD1,
    })) / 1e18;

  let bobBalanceD7 =
    (await tokenWeth.balanceOf(myaddress1, {
      blockTag: blockD7,
    })) / 1e18;

  let token1 = {};
  token1.balance = bobBalance;
  token1.price = ethPrice;
  token1.balanceUsd = token1.balance * ethPrice;
  token1.onlinedate = "5/30/2024";
  token1.get1D =
    (bobBalance - (bobBalanceD1 == 0 ? bobBalance : bobBalanceD1)) * ethPrice;
  token1.get7D =
    (bobBalance - (bobBalanceD7 == 0 ? bobBalance : bobBalanceD7)) * ethPrice;

  //==========================================================

  let bobBalance2 = Number(await tokenWeth.balanceOf(myaddress2)) / 1e18;

  let bobBalance2D1 =
    (await tokenWeth.balanceOf(myaddress2, {
      blockTag: blockD1,
    })) / 1e18;

  let bobBalance2D7 =
    (await tokenWeth.balanceOf(myaddress2, {
      blockTag: blockD7,
    })) / 1e18;

  let token2 = {};
  token2.balance = bobBalance2;
  token2.price = ethPrice;
  token2.balanceUsd = token2.balance * ethPrice;
  token2.onlinedate = "1/10/2025";
  token2.get1D =
    (bobBalance2 - (bobBalance2D1 == 0 ? bobBalance2 : bobBalance2D1)) *
    ethPrice;
  token2.get7D =
    (bobBalance2 - (bobBalance2D7 == 0 ? bobBalance2 : bobBalance2D7)) *
    ethPrice;

  //==========================================================

  let bobBalance3 = Number(await tokenWeth.balanceOf(myaddress3)) / 1e18;

  let bobBalance3D1 =
    (await tokenWeth.balanceOf(myaddress3, {
      blockTag: blockD1,
    })) / 1e18;

  let bobBalance3D7 =
    (await tokenWeth.balanceOf(myaddress3, {
      blockTag: blockD7,
    })) / 1e18;

  let token3 = {};
  token3.balance = bobBalance3;
  token3.price = ethPrice;
  token3.balanceUsd = token3.balance * ethPrice;
  token3.onlinedate = "1/10/2025";
  token3.get1D =
    (bobBalance3 - (bobBalance3D1 == 0 ? bobBalance3 : bobBalance3D1)) *
    ethPrice;
  token3.get7D =
    (bobBalance3 - (bobBalance3D7 == 0 ? bobBalance3 : bobBalance3D7)) *
    ethPrice;

  //==========================================================

  let bobBalance4 = Number(await tokenWbtc.balanceOf(myaddress4)) / 1e8;

  let bobBalance4D1 =
    (await tokenWbtc.balanceOf(myaddress4, {
      blockTag: blockD1,
    })) / 1e8;

  let bobBalance4D7 =
    (await tokenWbtc.balanceOf(myaddress4, {
      blockTag: blockD7,
    })) / 1e8;

  let token4 = {};
  token4.balance = bobBalance4;
  token4.price = btcPrice;
  token4.balanceUsd = token4.balance * btcPrice;
  token4.onlinedate = "2/3/2025";
  token4.get1D =
    (bobBalance4 - (bobBalance4D1 == 0 ? bobBalance4 : bobBalance4D1)) *
    btcPrice;
  token4.get7D =
    (bobBalance4 - (bobBalance4D7 == 0 ? bobBalance4 : bobBalance4D7)) *
    btcPrice;
  //==========================================================

  let bobBalance5 = Number(await tokenUsdc.balanceOf(myaddress5)) / 1e6;

  let bobBalance5D1 =
    (await tokenUsdc.balanceOf(myaddress5, {
      blockTag: blockD1,
    })) / 1e6;

  let bobBalance5D7 =
    (await tokenUsdc.balanceOf(myaddress5, {
      blockTag: blockD7,
    })) / 1e6;

  let token5 = {};
  token5.balance = bobBalance5;
  token5.price = 1;
  token5.balanceUsd = token5.balance * 1;
  token5.onlinedate = "2/3/2025";
  token5.get1D =
    bobBalance5 - (bobBalance5D1 == 0 ? bobBalance5 : bobBalance5D1);
  token5.get7D =
    bobBalance5 - (bobBalance5D7 == 0 ? bobBalance5 : bobBalance5D7);

  return [token1, token2, token3, token4, token5];
}

async function loadTon(apiTon, tonPrice) {
  let tonAddress = "UQBnfUj1-dRngPTGKDo77MNcTz-yFeBoJbhDSmfLxA9b__E4";
  let tonAddress1 = "UQARG_pcGwcE1gKUCZKWKu9Ennr9o9TRJ9tJK2Qd3tT9uPGs";
  let tonAddress2 = "UQBfLv-qYgQw59KckJXVqnul2uI8Cc2daM1h09zwmotBfQyI";
  let tonAddress3 = "UQCXSM5rG6OgbEIxW0KlZLpH3SRwNTJNDoMtYl1A4Xn_pIoD";
  let tonAddress4 = "UQDa4uDOHmvk5OI4jBMvgg84qjbtHeRankG97ZFxJMp1mmVR";
  let tonAddress5 = "UQDa3B6za6u3fEv_J1QfnsKzRtK8EiDEzCIA047q_I74foUh";
  // treasury_ton
  let tonAddress6 = "UQDsKO-9OO3FHdlryASWTCgYhKBN3ROpAVpfIWENdUenNhIp"; // this is not using but for referring
  let usdtAddress6 = Address.parse(
    "EQCSO0HpHhVf4YNQJmOy7Snx9WOF4W6SUKZZzPUR4bMzp1oN"
  );
  //----------------------------------

  let masterchainInfo = await apiTon.getLastBlock();

  let account = await apiTon.getAccount(masterchainInfo.last.seqno, tonAddress);

  let account1 = await apiTon.getAccount(
    masterchainInfo.last.seqno,
    tonAddress1
  );
  let account2 = await apiTon.getAccount(
    masterchainInfo.last.seqno,
    tonAddress2
  );
  let account3 = await apiTon.getAccount(
    masterchainInfo.last.seqno,
    tonAddress3
  );
  let account4 = await apiTon.getAccount(
    masterchainInfo.last.seqno,
    tonAddress4
  );

  let account5 = await apiTon.getAccount(
    masterchainInfo.last.seqno,
    tonAddress5
  );

  const usdtAddress6Data = await apiTon.runMethod(
    masterchainInfo.last.seqno,
    usdtAddress6,
    "get_wallet_data"
  );

  let tonBalance = Number(account.account.balance.coins) / 1e9;

  let tonBalanceD1 = (await getTonBalance(apiTon, tonAddress, 1)) / 1e9;

  let tonBalanceD7 = (await getTonBalance(apiTon, tonAddress, 7)) / 1e9;

  let token = {};
  token.balance = tonBalance;
  token.price = tonPrice;
  token.balanceUsd = token.balance * tonPrice;
  token.onlinedate = "test";
  token.get1D = tonBalance - (tonBalanceD1 == 0 ? tonBalance : tonBalanceD1);
  token.get7D = tonBalance - (tonBalanceD7 == 0 ? tonBalance : tonBalanceD7);
  //-----------------------------

  let tonBalance1 = Number(account1.account.balance.coins) / 1e9;

  let tonBalance1D1 = (await getTonBalance(apiTon, tonAddress1, 1)) / 1e9;
  let tonBalance1D7 = (await getTonBalance(apiTon, tonAddress1, 7)) / 1e9;

  let token1 = {};
  token1.balance = tonBalance1;
  token1.price = tonPrice;
  token1.balanceUsd = token1.balance * tonPrice;
  token1.onlinedate = "7/27/2024";
  token1.get1D =
    tonBalance1 - (tonBalance1D1 == 0 ? tonBalance1 : tonBalance1D1);
  token1.get7D =
    tonBalance1 - (tonBalance1D7 == 0 ? tonBalance1 : tonBalance1D7);

  //-----------------------------
  let tonBalance2 = Number(account2.account.balance.coins) / 1e9;

  let tonBalance2D1 = (await getTonBalance(apiTon, tonAddress2, 1)) / 1e9;
  let tonBalance2D7 = (await getTonBalance(apiTon, tonAddress2, 7)) / 1e9;

  let token2 = {};
  token2.balance = tonBalance2;
  token2.price = tonPrice;
  token2.balanceUsd = token2.balance * tonPrice;
  token2.onlinedate = "9/22/2024";
  token2.get1D =
    tonBalance2 - (tonBalance2D1 == 0 ? tonBalance2 : tonBalance2D1);
  token2.get7D =
    tonBalance2 - (tonBalance2D7 == 0 ? tonBalance2 : tonBalance2D7);

  //-----------------------------
  let tonBalance3 = Number(account3.account.balance.coins) / 1e9;

  let tonBalance3D1 = (await getTonBalance(apiTon, tonAddress3, 1)) / 1e9;
  let tonBalance3D7 = (await getTonBalance(apiTon, tonAddress3, 7)) / 1e9;

  let token3 = {};
  token3.balance = tonBalance3;
  token3.price = tonPrice;
  token3.balanceUsd = token3.balance * tonPrice;
  token3.onlinedate = "10/19/2024";
  token3.get1D =
    tonBalance3 - (tonBalance3D1 == 0 ? tonBalance3 : tonBalance3D1);
  token3.get7D =
    tonBalance3 - (tonBalance3D7 == 0 ? tonBalance3 : tonBalance3D7);

  //-----------------------------
  let tonBalance4 = Number(account4.account.balance.coins) / 1e9;

  let tonBalance4D1 = (await getTonBalance(apiTon, tonAddress4, 1)) / 1e9;
  let tonBalance4D7 = (await getTonBalance(apiTon, tonAddress4, 7)) / 1e9;

  let token4 = {};
  token4.balance = tonBalance4;
  token4.price = tonPrice;
  token4.balanceUsd = token4.balance * tonPrice;
  token4.onlinedate = "10/19/2024";
  token4.get1D =
    tonBalance4 - (tonBalance4D1 == 0 ? tonBalance4 : tonBalance4D1);
  token4.get7D =
    tonBalance4 - (tonBalance4D7 == 0 ? tonBalance4 : tonBalance4D7);

  //-----------------------------
  let tonBalance5 = Number(account5.account.balance.coins) / 1e9;

  let tonBalance5D1 = (await getTonBalance(apiTon, tonAddress5, 1)) / 1e9;
  let tonBalance5D7 = (await getTonBalance(apiTon, tonAddress5, 7)) / 1e9;

  let token5 = {};
  token5.balance = tonBalance5;
  token5.price = tonPrice;
  token5.balanceUsd = token5.balance * tonPrice;
  token5.onlinedate = "10/29/2024";
  token5.get1D =
    tonBalance5 - (tonBalance5D1 == 0 ? tonBalance5 : tonBalance5D1);
  token5.get7D =
    tonBalance5 - (tonBalance5D7 == 0 ? tonBalance5 : tonBalance5D7);

  //-----------------------------

  let usdtBalance6 = Number(usdtAddress6Data.result[0].value) / 1e6;

  let token6 = {};
  token6.balance = usdtBalance6;
  token6.price = 1;
  token6.balanceUsd = token6.balance;
  token6.onlinedate = "2/11/2024";
  token6.get1D = 0;
  token6.get7D = 0;

  // -------------------------------------------
  return [token, token1, token2, token3, token4, token5, token6];
}

async function loadSolana(apiSol, solPrice) {
  let tonAddress1 = new PublicKey(
    "6CUz8TXkj2gmK7zLNNbFrWM7dbvUnhBguxknDho5xuk8"
  );
  let tonAddress2 = new PublicKey(
    "CtQaw31NcGuFqNdRSehy7U5UXFxEoydwHq8XYaQ3iWKv"
  );
  let tonAddress3 = new PublicKey(
    "6TFoo9XReBuH7eLvsuCR6p5GK4wasvhmrdakf5X4v1u2"
  );

  let response = await axios.get("https://kunkuicryptodata.com:18081/sol");

  const solBalance1 = response.data.balanceSol[0];
  const solBalance2 = response.data.balanceSol[1];
  const solBalance3 = response.data.balanceSol[2];

  const usdcBalance1 = response.data.balanceUsdc[0];

  let solBalance1D1 = solBalance1;
  let solBalance2D1 = solBalance2;
  let solBalance3D1 = solBalance3;

  if (response.data.day1BalanceSol.length > 0) {
    solBalance1D1 = response.data.day1BalanceSol[0];
    solBalance2D1 = response.data.day1BalanceSol[1];
    solBalance3D1 = response.data.day1BalanceSol[2];
  }

  let solBalance1D7 = solBalance1;
  let solBalance2D7 = solBalance2;
  let solBalance3D7 = solBalance3;

  if (response.data.day7BalanceSol.length > 0) {
    solBalance1D7 = response.data.day1BalanceSol[0];
    solBalance2D7 = response.data.day1BalanceSol[1];
    solBalance3D7 = response.data.day1BalanceSol[2];
  }

  // usdc ------------ only for the first account
  let usdcBalance1D1 = usdcBalance1;

  if (response.data.day1BalanceUsdc.length > 0) {
    usdcBalance1D1 = response.data.day1BalanceUsdc[0];
  }

  let usdcBalance1D7 = usdcBalance1;

  if (response.data.day7BalanceUsdc.length > 0) {
    usdcBalance1D7 = response.data.day7BalanceUsdc[0];
  }

  //-----------------------
  let token1 = {};
  token1.balance = solBalance1 * solPrice + usdcBalance1;
  token1.price = 1;
  token1.balanceUsd = token1.balance;
  token1.onlinedate = "1/22/2025";

  let get1DSol =
    solBalance1 - (solBalance1D1 == 0 ? solBalance1 : solBalance1D1);
  let get1DUsdc =
    usdcBalance1 - (usdcBalance1D1 == 0 ? usdcBalance1 : usdcBalance1D1);
  let get7DSol =
    solBalance1 - (solBalance1D7 == 0 ? solBalance1 : solBalance1D7);
  let get7DUsdc =
    usdcBalance1 - (usdcBalance1D7 == 0 ? usdcBalance1 : usdcBalance1D7);

  token1.get1D = get1DSol * solPrice + get1DUsdc;
  token1.get7D = get7DSol * solPrice + get7DUsdc;

  //-----------------------------

  let token2 = {};
  token2.balance = solBalance2;
  token2.price = solPrice;
  token2.balanceUsd = token2.balance * solPrice;
  token2.onlinedate = "1/22/2025";
  token2.get1D =
    solBalance2 - (solBalance2D1 == 0 ? solBalance2 : solBalance2D1);
  token2.get7D =
    solBalance2 - (solBalance2D7 == 0 ? solBalance2 : solBalance2D7);

  //-----------------------------

  let token3 = {};
  token3.balance = solBalance3;
  token3.price = solPrice;
  token3.balanceUsd = token3.balance * solPrice;
  token3.onlinedate = "1/22/2025";
  token3.get1D =
    solBalance3 - (solBalance3D1 == 0 ? solBalance3 : solBalance3D1);
  token3.get7D =
    solBalance3 - (solBalance3D7 == 0 ? solBalance3 : solBalance3D7);

  //-----------------------------
  return [token1, token2, token3];
}

async function loadBera(provider) {
  let myaddress1 = "0xFcAAa6f52bEf837bdEF69C5ff116896E06FBC212";

  const tokenHoney = new ethers.Contract(
    "0xFCBD14DC51f0A4d49d5E53C2E0950e0bC26d0Dce",
    ["function balanceOf(address user) public view returns (uint)"],
    provider
  );

  // same as frax
  let blockD1 = await getFraxtalBlock(provider, 1);
  let blockD7 = await getFraxtalBlock(provider, 7);

  let honeyBalance = Number(await tokenHoney.balanceOf(myaddress1)) / 1e18;

  let honeyBalanceD1 =
    (await tokenHoney.balanceOf(myaddress1, {
      blockTag: blockD1,
    })) / 1e18;

  let honeyBalanceD7 =
    (await tokenHoney.balanceOf(myaddress1, {
      blockTag: blockD7,
    })) / 1e18;

  let token1 = {};
  token1.balance = honeyBalance;
  token1.price = 1;
  token1.balanceUsd = token1.balance * 1;
  token1.onlinedate = "2/15/2025";
  token1.get1D =
    honeyBalance - (honeyBalanceD1 == 0 ? honeyBalance : honeyBalanceD1);

  token1.get7D =
    honeyBalance - (honeyBalanceD7 == 0 ? honeyBalance : honeyBalanceD7);

  return [token1];
}

// ------------------------------
let capitalSum = 0;
// ------------------------------
// ------------------------------
// ------------------------------
// ------------------------------
// ------------------------------
// ------------------------------
// ------------------------------
// ------------------------------
// ------------------------------
// ------------------------------
// ------------------------------
// ------------------------------
const init = async function () {
  // const dotPrice = await getPrice("polkadot");
  // const filPrice = await getPrice("filecoin");
  // const glmrPrice = await getPrice("moonbeam");
  // const bncPrice = await getPrice("bifrost-native-coin");
  // const kusdPrice = await getPrice("acala-dollar");
  // const ksmPrice = await getPrice("kusama");
  // const btcPrice = await getPrice("bitcoin");
  showProcess("loading ...");

  let [
    dotPrice,
    filPrice,
    glmrPrice,
    ksmPrice,
    btcPrice,
    ethPrice,
    acaPrice,
    tonPrice,
    solPrice,
  ] = await Promise.all([
    getPriceNew("DOT-USDT"),
    getPriceNew("FIL-USDT"),
    getPriceNew("GLMR-USDT"),
    getPriceNew("KSM-USDT"),
    getPriceNew("BTC-USDT"),
    getPriceNew("ETH-USDT"),
    getPriceNew("ACA-USDT"),
    getPriceNew("TON-USDT"),
    getPriceNew("SOL-USDT"),
  ]);

  dotPrice = Number(dotPrice.data.data[0][1]);
  filPrice = Number(filPrice.data.data[0][1]);
  glmrPrice = Number(glmrPrice.data.data[0][1]);
  ksmPrice = Number(ksmPrice.data.data[0][1]);
  btcPrice = Number(btcPrice.data.data[0][1]);
  ethPrice = Number(ethPrice.data.data[0][1]);
  acaPrice = Number(acaPrice.data.data[0][1]);
  tonPrice = Number(tonPrice.data.data[0][1]);
  solPrice = Number(solPrice.data.data[0][1]);

  let bncPrice = await getPrice("bifrost-native-coin");
  let kusdPrice = await getPrice("acala-dollar");

  const usdtPrice = 1;
  showProcess("prices loaded ...");
  showProcess("loading API ...");
  showProcess("api 1 karura loading ...");
  const apiKarura = await ApiPromise.create({
    provider: new WsProvider("wss://karura-rpc.dwellir.com"),
  });
  // showProcess("api 2 loading ...");
  // const apiInterlay = await ApiPromise.create({
  //   provider: new WsProvider("wss://api.interlay.io:443/parachain"),
  // });
  showProcess("api 2 bifrost loading ...");
  const apiBifrost = await ApiPromise.create({
    provider: new WsProvider("wss://bifrost-rpc.liebi.com/ws"),
  });
  showProcess("api 3 acala loading ...");
  const apiAcala = await ApiPromise.create({
    provider: new WsProvider("wss://acala-rpc.dwellir.com"),
  });

  showProcess("api 4  bifrost loading ...");
  const apiBifrostDot = await ApiPromise.create({
    provider: new WsProvider("wss://hk.p.bifrost-rpc.liebi.com/ws"),
  });

  showProcess("api 5 op loading ...");
  const providerOp = new ethers.providers.StaticJsonRpcProvider(
    "https://mainnet.optimism.io",
    {
      chainId: 10,
      name: "OP Mainnet",
    }
  );
  showProcess("api 6 matic oading ...");
  const providerMatic = new ethers.providers.StaticJsonRpcProvider(
    "https://polygon-rpc.com",
    {
      chainId: 137,
      name: "matic",
    }
  );

  showProcess("api 7 mode loading ...");

  const providerMode = new ethers.providers.StaticJsonRpcProvider(
    "https://mainnet.mode.network/",
    {
      chainId: 34443,
      name: "mode",
    }
  );
  showProcess("api 8 merlin loading ...");
  const providerMerlin = new ethers.providers.StaticJsonRpcProvider(
    "https://rpc.merlinchain.io",
    {
      chainId: 4200,
      name: "merlin",
    }
  );

  showProcess("api 9 frax loading ...");
  const apiFraxtal = new ethers.providers.StaticJsonRpcProvider(
    "https://rpc.frax.com",
    {
      chainId: 252,
      name: "fraxtal",
    }
  );

  showProcess("api 10 blast loading ...");
  const apiScroll = new ethers.providers.StaticJsonRpcProvider(
    "https://rpc.scroll.io",
    {
      chainId: 534352,
      name: "scroll",
    }
  );

  showProcess("api 11 b2 loading ...");
  const apiB2 = new ethers.providers.StaticJsonRpcProvider(
    "https://rpc.bsquared.network",
    {
      chainId: 223,
      name: "bsquared",
    }
  );

  showProcess("api 12 bob loading ...");
  const apiBob = new ethers.providers.StaticJsonRpcProvider(
    "https://rpc.gobob.xyz",
    {
      chainId: 60808,
      name: "bob",
    }
  );
  // ton.TonClient4
  showProcess("api 13 ton loading ...");
  const apiTon = new TonClient4({
    endpoint: "https://mainnet-v4.tonhubapi.com",
  });

  showProcess("api 14 hydradx loading ...");
  const apiHydradx = await ApiPromise.create({
    provider: new WsProvider("wss://hydradx-rpc.dwellir.com"),
  });

  showProcess("api 15 polkadot loading ...");
  const apiDot = await ApiPromise.create({
    provider: new WsProvider("wss://polkadot-rpc.dwellir.com"),
  });

  // showProcess("api 16 parallelloading  ...");
  // const apiParallel = await ApiPromise.create({
  //   provider: new WsProvider("wss://polkadot-parallel-rpc.parallel.fi"),
  //   // // provider: new WsProvider("wss://parallel-rpc.dwellir.com"),
  //   // provider: new HttpProvider("https://parallel-rpc.dwellir.com"),
  // });

  showProcess("api 17 solana loading ...");
  const apiSol = new Connection(
    "https://sly-compatible-seed.solana-mainnet.quiknode.pro/4206f2cb5e05b49fb9def43ccc0efbe159fbe7e6"
  );

  showProcess("api 18 berachain loading ...");
  const apiBera = new ethers.providers.WebSocketProvider(
    "wss://rpc.berachain.com/"
  );

  showProcess("api loaded ...");
  showProcess("loading bots ...");

  // loadIDEX().then((res) => {
  //   render(res, "idex_usdc");
  // });

  loadAcala(apiAcala, dotPrice).then((res) => {
    render(res[0], "acala_ashburn");
    render(res[1], "acala_ashburn_ag");
    render(res[2], "acala_DP");
    render(res[3], "acala_batch");
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  loadKarura(apiKarura, kusdPrice).then((res) => {
    render(res[0], "karura_helsinki");
    render(res[1], "karura_us");
    // render(res[1], "karura_ohio");
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  // loadBifrost(apiBifrost, bncPrice).then((res) => {
  //   // render(res[0], "bifrost_dot");
  //   // render(res[1], "bifrost_glmr");
  //   render(res[0], "bifrost_bnc");
  //   capitalSum = capitalSum + getBalance(res);
  //   showBalance(capitalSum);
  // });

  loadHydraDx(apiBifrostDot, apiHydradx, dotPrice).then((res) => {
    render(res[0], "hydradx");
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  loadBifrost3(apiBifrost, usdtPrice, ksmPrice).then((res) => {
    // render(res[0], "bifrost_bnc_loop");
    render(res[0], "bifrost_usdt");
    render(res[1], "bifrost_ksm");
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  loadBifrost4(apiBifrostDot, dotPrice, filPrice, glmrPrice).then((res) => {
    render(res[0], "bifrost_mul");
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  // loadBifrost5(apiBifrostDot, dotPrice).then((res) => {
  //   render(res[0], "bifrost_usdt_ref");
  //   capitalSum = capitalSum + getBalance(res);
  //   showBalance(capitalSum);
  // });

  loadBifrost6(apiBifrostDot, dotPrice, bncPrice).then((res) => {
    render(res[0], "bifrost_bnc_ref");
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  loadCross(apiAcala, dotPrice).then((res) => {
    render(res[0], "acala_cross");
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  // loadParallelLcdot().then((res) => {
  //   render(res, "parallel_lcdot");
  //   capitalSum = capitalSum + res.balanceUsd;
  //   showBalance(capitalSum);
  // });

  loadCrossKsm(apiKarura, apiBifrost, kusdPrice, ksmPrice).then((res) => {
    render(res, "acala_bifrost");
    capitalSum = capitalSum + res.balanceUsd;
    showBalance(capitalSum);
  });

  openSeaS1(providerMatic, ethPrice).then((res) => {
    renderDig(res, "opensea_nft1", 3);
    capitalSum = capitalSum + res.balanceUsd;
    showBalance(capitalSum);
  });

  openSeaS2(providerMatic, ethPrice).then((res) => {
    renderDig(res, "opensea_nft2", 3);
    capitalSum = capitalSum + res.balanceUsd;
    showBalance(capitalSum);
  });

  openSeaS3(providerMatic, ethPrice).then((res) => {
    renderDig(res, "opensea_nft3", 3);
    capitalSum = capitalSum + res.balanceUsd;
    showBalance(capitalSum);
  });

  treasureOP(providerOp, ethPrice).then((res) => {
    render(res, "treasure_op");
    capitalSum = capitalSum + res.balanceUsd;
    showBalance(capitalSum);
  });

  treasureMatic(providerMatic, ethPrice).then((res) => {
    render(res, "treasure_matic");
    capitalSum = capitalSum + res.balanceUsd;
    showBalance(capitalSum);
  });
  // zerion().then((res) => {
  //   render(res[0], "treasure_op");
  //   render(res[1], "treasure_matic");
  //   capitalSum = capitalSum + getBalance(res);
  //   showBalance(capitalSum);
  // });

  treasureBTC(btcPrice).then((res) => {
    renderDig(res, "treasure_btc", 5);
    capitalSum = capitalSum + res.balanceUsd;
    showBalance(capitalSum);
  });

  btcNft(btcPrice).then((res) => {
    renderDig(res, "btc_nft", 5);
    capitalSum = capitalSum + res.balanceUsd;
    showBalance(capitalSum);
  });

  // loadParallelLdot(apiParallel, dotPrice).then((res) => {
  //   // render(res[0], "parallel_ldot");
  //   render(res[0], "parallel_cross_dot");
  //   capitalSum = capitalSum + getBalance(res);
  //   showBalance(capitalSum);
  // });

  // loadInterlay(apiInterlay, btcPrice, dotPrice, usdtPrice).then((res) => {
  //   renderDig(res[0], "interlay_dot", 4);
  //   renderDig(res[1], "interlay_usdt", 4);
  //   capitalSum = capitalSum + getBalance(res);
  //   showBalance(capitalSum);
  // });

  loadPolkadot(apiDot, dotPrice).then((res) => {
    render(res[0], "treasury_parallel");
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  // loadInterlayRef(apiInterlay, btcPrice, dotPrice).then((res) => {
  //   render(res[0], "interlay_dot_ref");
  //   render(res[1], "interlay_usdt_ref");
  //   capitalSum = capitalSum + getBalance(res);
  //   showBalance(capitalSum);
  // });

  // loadParallel(apiParallel, dotPrice).then((res) => {
  //   render(res[0], "parallel_sdot");
  //   render(res[1], "parallel_usdt");
  //   capitalSum = capitalSum + getBalance(res);
  //   showBalance(capitalSum);
  // });

  loadAcaRef(apiAcala, dotPrice, acaPrice).then((res) => {
    render(res[0], "acala_aca");
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  loadMode(providerMode, ethPrice).then((res) => {
    renderDigSpe(res[0], "mode_usdc", 3);
    render(res[1], "mode_usdc_test");
    render(res[2], "mode_usdc_z");
    renderDigSpe(res[3], "mode_usdc_yk", 3);
    render(res[4], "mode_usdc_v4");
    render(res[5], "mode_usdc_izi");
    renderDigSpe(res[6], "mode_weth_bmx", 3);

    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  loadMerlin(providerMerlin, btcPrice).then((res) => {
    renderDigSpe(res[0], "merlin1", 5);
    renderDigSpe(res[1], "merlin2", 5);

    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  loadFrax(apiFraxtal, ethPrice).then((res) => {
    renderDig(res[0], "frax_v3", 3);
    renderDig(res[1], "frax_treasure", 3);
    renderDig(res[2], "frax_curve_weth", 3);
    renderDig(res[3], "frax_curve_frax", 2);
    renderDig(res[4], "frax_frax_balancer", 2);
    renderDig(res[5], "frax_velo_frax", 2);
    renderDig(res[6], "frax_velo_Weth", 3);
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  loadScroll(apiScroll, ethPrice).then((res) => {
    renderDig(res[0], "scroll_test", 3);
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  loadBob(apiBob, ethPrice, btcPrice).then((res) => {
    renderDigSpe(res[0], "bob_1", 3);
    renderDigSpe(res[1], "bob_2", 3);
    renderDigSpe(res[2], "bob_3", 3);
    renderDigSpe(res[3], "bob_4", 5);
    render(res[4], "bob_5");
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  loadB2(apiB2, btcPrice).then((res) => {
    renderDigSpe(res[0], "B2_1", 5);
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  loadTon(apiTon, tonPrice).then((res) => {
    render(res[0], "ton_test");
    render(res[1], "ton_1");
    render(res[2], "ton_2");
    render(res[3], "ton_3");
    render(res[4], "ton_4");
    render(res[5], "ton_5");
    render(res[6], "treasury_ton");
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  loadSolana(apiSol, solPrice).then((res) => {
    render(res[0], "treasury_sol");
    render(res[1], "sol_1");
    render(res[2], "sol_2");
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });

  loadBera(apiBera).then((res) => {
    render(res[0], "bera_test");
    capitalSum = capitalSum + getBalance(res);
    showBalance(capitalSum);
  });
};

//https://api.coingecko.com/api/v3/simple/price?ids=polkadot&vs_currencies=usd
const getPrice = async function (id) {
  let cpdata = new Promise(async (resolve, reject) => {
    let response;
    try {
      response = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price",
        {
          headers: { "Access-Control-Allow-Origin": "*" },
          params: {
            ids: id,
            vs_currencies: "usd",
          },
        }
      );
    } catch (ex) {
      reject(ex);
    }
    if (response) {
      const json = response.data;
      resolve(json);
    }
  });

  try {
    let price = await cpdata;
    return price[id]["usd"];
  } catch (ex) {
    return -1;
  }
};

const getPriceNew = async function (id) {
  let price = axios.get(
    `https://www.okx.com/api/v5/market/candles?instId=${id}&bar=1m&limit=1`
  );
  return price;
};

function toNumber(num, db, dig) {
  return Number(num.div(ethers.utils.parseUnits("1", db - dig)) / 10 ** dig);
}

const calculateAPR = function (current, inv, onlinedate) {
  let time = Date.parse(new Date());
  let lasttime = Date.parse(onlinedate);
  const ms2day = 1000 * 3600 * 24;
  const duration = parseInt(time - lasttime) / ms2day;
  let rate = ((((current - inv) / duration) * 365) / inv) * 100;
  return rate;
};

async function getBalancePara(api, token, address, backDay, digtal = 10) {
  let blockBack = 0;

  const currentBlock = await api.rpc.chain.getBlock();
  if (backDay > 0) {
    blockBack = backBlock(12, backDay);
  }
  const blockNumber =
    currentBlock.block.toHuman().header.number.replaceAll(",", "") - blockBack;

  const blockHash = await api.rpc.chain.getBlockHash(blockNumber);
  const apiAt = await api.at(blockHash);
  let balance = await apiAt.query.assets.account(token, address);

  return balance.toHuman()
    ? balance.toHuman().balance.replaceAll(",", "") / 10 ** digtal
    : 0;
}

async function getBalanceAcala(api, token, address, backDay, digtal = 10) {
  let blockBack = 0;

  const currentBlock = await api.rpc.chain.getBlock();
  if (backDay > 0) {
    blockBack = backBlock(12, backDay);
  }
  const blockNumber =
    currentBlock.block.toHuman().header.number.replaceAll(",", "") - blockBack;

  const blockHash = await api.rpc.chain.getBlockHash(blockNumber);
  const apiAt = await api.at(blockHash);
  let balance = await apiAt.query.tokens.accounts(address, { Token: token });

  return balance.toHuman()
    ? balance.toHuman().free.replaceAll(",", "") / 10 ** digtal
    : 0;
}

async function getBalanceAcalaAca(api, address, backDay, digtal = 10) {
  let blockBack = 0;

  const currentBlock = await api.rpc.chain.getBlock();
  if (backDay > 0) {
    blockBack = backBlock(12, backDay);
  }
  const blockNumber =
    currentBlock.block.toHuman().header.number.replaceAll(",", "") - blockBack;

  const blockHash = await api.rpc.chain.getBlockHash(blockNumber);
  const apiAt = await api.at(blockHash);

  let balance = await apiAt.query.system.account(address);
  // let balance = await apiAt.query.tokens.accounts(address, { Token: token });

  return balance.toHuman() ? Number(balance.data.free) / 10 ** digtal : 0;
}

async function getBalanceHydra(api, token, address, backDay, digtal = 10) {
  let blockBack = 0;

  const currentBlock = await api.rpc.chain.getBlock();
  if (backDay > 0) {
    blockBack = backBlock(12, backDay);
  }
  const blockNumber =
    currentBlock.block.toHuman().header.number.replaceAll(",", "") - blockBack;

  const blockHash = await api.rpc.chain.getBlockHash(blockNumber);

  const apiAt = await api.at(blockHash);
  let balance = await apiAt.query.tokens.accounts(address, token);

  return Number(balance.free) ? Number(balance.free) / 10 ** digtal : 0;
}

async function getBalanceAcalaLcdot(api, address, backDay, digtal = 10) {
  let blockBack = 0;

  const currentBlock = await api.rpc.chain.getBlock();
  if (backDay > 0) {
    blockBack = backBlock(12, backDay);
  }
  const blockNumber =
    currentBlock.block.toHuman().header.number.replaceAll(",", "") - blockBack;

  const blockHash = await api.rpc.chain.getBlockHash(blockNumber);
  const apiAt = await api.at(blockHash);
  let balance = await apiAt.query.tokens.accounts(address, {
    LiquidCrowdloan: 13,
  });

  return balance.toHuman()
    ? balance.toHuman().free.replaceAll(",", "") / 10 ** digtal
    : 0;
}

async function getBalanceInterlay(api, address, token, backDay, digtal = 10) {
  let blockBack = 0;

  const currentBlock = await api.rpc.chain.getBlock();
  if (backDay > 0) {
    blockBack = backBlock(12, backDay);
  }
  const blockNumber =
    currentBlock.block.toHuman().header.number.replaceAll(",", "") - blockBack;

  const blockHash = await api.rpc.chain.getBlockHash(blockNumber);
  const apiAt = await api.at(blockHash);

  let balance = await apiAt.query.tokens.accounts.multi([[address, token]]);

  return Number(balance[0].free) ? Number(balance[0].free) / 10 ** digtal : 0;
}

async function getBalanceAcalaLdot(api, address, backDay, digtal = 10) {
  let blockBack = 0;

  const currentBlock = await api.rpc.chain.getBlock();
  if (backDay > 0) {
    blockBack = backBlock(12, backDay);
  }
  const blockNumber =
    currentBlock.block.toHuman().header.number.replaceAll(",", "") - blockBack;

  const blockHash = await api.rpc.chain.getBlockHash(blockNumber);
  const apiAt = await api.at(blockHash);
  let balance = await apiAt.query.tokens.accounts(address, {
    Token: "LDOT",
  });

  return balance.toHuman()
    ? balance.toHuman().free.replaceAll(",", "") / 10 ** digtal
    : 0;
}

async function getBalanceBifrost(
  api,
  token,
  tokenId,
  address,
  backDay,
  digtal = 10
) {
  let blockBack = 0;
  const currentBlock = await api.rpc.chain.getBlock();
  if (backDay > 0) {
    blockBack = backBlock(12, backDay);
  }
  const blockNumber =
    currentBlock.block.toHuman().header.number.replaceAll(",", "") - blockBack;

  const blockHash = await api.rpc.chain.getBlockHash(blockNumber);
  const apiAt = await api.at(blockHash);
  let tokenObj = {};
  tokenObj[token] = tokenId;
  let balance = await apiAt.query.tokens.accounts(address, tokenObj);

  return balance.free ? Number(balance.free) / 10 ** digtal : 0;
}

async function getBalanceBifrostBnc(api, address, backDay, digtal = 12) {
  let blockBack = 0;
  const currentBlock = await api.rpc.chain.getBlock();
  if (backDay > 0) {
    blockBack = backBlock(12, backDay);
  }
  const blockNumber =
    currentBlock.block.toHuman().header.number.replaceAll(",", "") - blockBack;

  const blockHash = await api.rpc.chain.getBlockHash(blockNumber);
  const apiAt = await api.at(blockHash);

  let balance = await apiAt.query.system.account(address);

  return balance.data.free ? Number(balance.data.free) / 10 ** digtal : 0;
}

function backBlock(blockTime, backDay) {
  const current = new Date();
  const startOfDay = new Date();
  startOfDay.setUTCHours(0, 0, 0, 0);
  const backTime = (backDay - 1) * 24 * 60 * 60 * 1000;
  const duration = current - (startOfDay - backTime);
  return parseInt(duration / (blockTime * 1000));
}

function backBlockTon(backDay) {
  // let MasterchainInfo = await client.getMasterchainInfo();

  const startOfDay = new Date();
  startOfDay.setUTCHours(0, 0, 0, 0);
  const backTime = (backDay - 1) * 24 * 60 * 60 * 1000;
  const blockTime = (startOfDay - backTime) / 1000;

  // let checkBlock = await client.lookupBlockByUtime({
  //   shard: MasterchainInfo.last.shard,
  //   workchain: -1,
  //   utime: blockTime,
  // });

  return blockTime;
}

function getBalance(tokens) {
  let sum = 0;
  for (let i = 0; i < tokens.length; i++) {
    sum = sum + tokens[i].balanceUsd;
  }

  return sum;
}

async function getEvmBlock(provider, backDay) {
  let blockBack = 0;
  const currentBlock = Number(await provider.getBlockNumber());
  if (backDay > 0) {
    blockBack = backBlock(12, backDay);
  }
  const blockNumber = currentBlock - blockBack;
  return blockNumber;
}

async function getMaticBlock(provider, backDay) {
  let blockBack = 0;
  const currentBlock = Number(await provider.getBlockNumber());
  if (backDay > 0) {
    blockBack = backBlock(2, backDay);
  }
  const blockNumber = currentBlock - blockBack;
  return blockNumber;
}

async function getModeBlock(provider, backDay) {
  let blockBack = 0;
  const currentBlock = Number(await provider.getBlockNumber());
  if (backDay > 0) {
    blockBack = backBlock(2, backDay);
  }
  const blockNumber = currentBlock - blockBack;
  return blockNumber;
}

async function getFraxtalBlock(provider, backDay) {
  let blockBack = 0;
  const currentBlock = Number(await provider.getBlockNumber());
  if (backDay > 0) {
    blockBack = backBlock(2, backDay);
  }
  const blockNumber = currentBlock - blockBack;
  return blockNumber;
}

async function getTonBalance(client, wallet, backDay) {
  let blockTime = backBlockTon(backDay);

  let checkBlock = await client.getBlockByUtime(blockTime);
  let account = await client.getAccount(checkBlock.shards[0].seqno, wallet);

  let balance = Number(account.account.balance.coins);

  return balance;
}

//-------------------------------------------------
async function getBalanceSolana(client, wallet, tokenAddress) {
  const tokenAccounts = await client.getParsedTokenAccountsByOwner(wallet, {
    programId: TOKEN_PROGRAM_ID,
  });

  let balance = 0;
  for (let accountInfo of tokenAccounts.value) {
    let accountData = accountInfo.account.data.parsed.info;
    let mintAddress = accountData.mint;

    if (mintAddress === tokenAddress) {
      balance = accountData.tokenAmount.uiAmount;
      break;
    }
  }

  return balance;
}
// look back balance not working
// ----------------- not working --------------
async function getSolBalance(client, wallet, backDay) {
  let blockHeight = getSolBlock(client, backDay);

  const blockInfo = await client.getBlock(blockHeight);
  // Get SOL balance at the specific block
  const solBalance = await client.getBalance(wallet, {
    commitment: "confirmed",
  });

  return solBalance;
}

async function getSolBlock(provider, backDay) {
  let blockBack = 0;

  const epochInfo = await provider.getEpochInfo();
  const currentBlock = epochInfo.blockHeight;
  if (backDay > 0) {
    blockBack = backBlock(0.4, backDay);
  }
  const blockNumber = currentBlock - blockBack;
  return blockNumber;
}
// ----------------- not working --------------

function getSOC(aDOT, bDOT, priceSoc) {
  let SoC = (bDOT * priceSoc) / (aDOT + bDOT * priceSoc);
  if (isNaN(SoC)) {
    SoC = 0;
  }

  return SoC;
}

function urlfy(param) {
  let paramArray = [];
  Object.keys(param).forEach((key) => paramArray.push(key + "=" + param[key]));
  return "?" + paramArray.join("&");
}

function getTimeStamp() {
  let timeStamp = new Date(Date.now());
  return timeStamp.toISOString();
}

function signTrans(method, timestamp, query) {
  let signature = "";
  signature = Base64.stringify(
    hmacSHA256(timestamp + method + query, "3A4DFAA99D782CB7440B2F7F1367D089")
  );
  return signature;
}

function getStakingRateBN(poolStaking) {
  const a = new BN(poolStaking.value.a).div(new BN(100));
  const D = new BN(poolStaking.value.totalSupply);
  const x = new BN(poolStaking.value.balances[0]);
  const y = new BN(poolStaking.value.balances[1]);
  // let aa = ((D ^ 3) / (4 * x * y) - D) / (4 * (x + y) - D * 4);

  let n4 = new BN(4);
  let n16 = new BN(16);
  // n16 * a * x * y + n16 * a * (x + y) * y + (n4 * D - n16 * a * D) * y;
  const dx = n16
    .mul(a)
    .mul(x)
    .mul(y)
    .add(n16.mul(a).mul(x.add(y)).mul(y))
    .add(n4.mul(D).sub(n16.mul(a).mul(D)).mul(y));

  const dy = n16
    .mul(a)
    .mul(x)
    .mul(y)
    .add(n16.mul(a).mul(x.add(y)).mul(x))
    .add(n4.mul(D).sub(n16.mul(a).mul(D)).mul(x));

  const dydx = Number(dx) / Number(dy);
  return dydx;
}

function getStableprice(a, D, x, y) {
  const n4 = 4;
  const n16 = 16;

  const dx =
    n16 * a * x * y + n16 * a * (x + y) * y + (n4 * D - n16 * a * D) * y;

  const dy =
    n16 * a * x * y + n16 * a * (x + y) * x + (n4 * D - n16 * a * D) * x;

  const dydx = dx / dy;
  return dydx;
}

function getStakingRate(poolStaking) {
  const a = myToNum(poolStaking.value.a.words) / 100;
  const D = myToNum(poolStaking.value.totalSupply.words);
  const x = myToNum(poolStaking.value.balances[0].words);
  const y = myToNum(poolStaking.value.balances[1].words);

  const n4 = 4;
  const n16 = 16;

  const dx =
    n16 * a * x * y + n16 * a * (x + y) * y + (n4 * D - n16 * a * D) * y;

  const dy =
    n16 * a * x * y + n16 * a * (x + y) * x + (n4 * D - n16 * a * D) * x;

  const dydx = dx / dy;
  return dydx;
}

function myToNum(digtals) {
  if (digtals.length == 2) {
    return digtals[0] + digtals[1] * 67108864;
  } else if (digtals.length == 3) {
    return (
      digtals[0] + digtals[1] * 67108864 + digtals[2] * 67108864 * 67108864
    );
  } else if (digtals.length == 4) {
    return (
      digtals[0] +
      digtals[1] * 67108864 +
      digtals[2] * 67108864 * 67108864 +
      digtals[3] * 67108864 * 67108864 * 67108864
    );
  } else {
    return digtals[0];
  }
}

async function getJettonBalance(masterchainInfo, client, jetton) {
  const boced = tonCore
    .serializeTuple([
      {
        type: "slice",
        cell: ton.beginCell().endCell(),
      },
    ])
    .toBoc({ idx: false, crc32: false });

  const res = await client.runMethod(
    jetton,
    "get_wallet_data",
    boced,
    masterchainInfo.last
  );

  let result = new tonCore.TupleReader(
    tonCore.parseTuple(
      tonCore.Cell.fromBoc(Buffer.from(res.result, "base64"))[0]
    )
  );
  return result.items[1].value; // must use bigint, to avoid loss accuracy
}

function waitfun(delay) {
  return new Promise((reslove) => {
    setTimeout(reslove, delay);
  });
}

init();
